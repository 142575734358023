import * as types from './mutation-types'

export const loadItems = ({ commit, dispatch, state }, {
    page, search, limit, unit_id, item_type, orderByField, orderBy
}) => {
let url = `/items?page=${page}&search=${search}&limit=${limit}&unit_id=${unit_id}
&item_type=${item_type}&orderByField=${orderByField}&orderBy=${orderBy}`
return new Promise((resolve, reject) => {
    window.axios.get(url).then((response) => {
        commit(types.LOAD_ITEMS, response.data)
        resolve(response)
    }).catch((err) => {
        reject(err)
    })
})
}

export const createItem = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('items', data).then((response) => {
            commit(types.ADD_ITEM, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateItem = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('items/' + data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showItem = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('items/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteItems = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('items/delete', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getItemTypes = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/items/types').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}