// eslint-disable-next-line no-unused-vars

import { createStore } from 'vuex'

import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'


import auth from './modules/auth/index'
import user from './modules/user'
import contact from './modules/contact'
import conversations from './modules/conversations'

import purchase from './modules/purchase'
import estimate from './modules/estimate'
import invoice from './modules/invoice'
import currency from './modules/currency'
import tax from './modules/tax'
import item from './modules/item'
import items from './modules/items'
import units from './modules/units'
import payment from './modules/payment'
import recurring from "./modules/recurring";
import doc from './modules/doc'
import country from "./modules/country";
import permission from "./modules/permission";
import expense from "./modules/expense";
import reason from "./modules/reason";
import expense_type from "./modules/expense_type";
import role from "./modules/role"
import dashboard from "./modules/dashboard"
import ticket from "@/Modules/Ticket/store/"
import licence from "@/Modules/Licence/store/"
import reach from "./modules/demands/contacts";
import demo from "./modules/demands/demos";
import request from "@/Modules/Kobo/Request/store/module/request"
import kobo from "@/Modules/Kobo/store/index"
import validation_step from './modules/validation_step'
import withdrawal from "@/Modules/Kobo/Withdrawal/store/module/config"
import dokoun from "@/Modules/Dokoun/store/index"
import applications from "@/Modules/Application/store/index"

const initialState = {
  isAppLoaded: false
}

export default createStore({
  // strict: true,
  state: initialState,

  getters,
  mutations,
  actions,
  modules: {
    auth,
    user,
    contact,
    conversations,
    purchase,
    estimate,
    invoice,
    currency,
    tax,
    items,
    item,
    units,
    recurring,
    payment,
    country,
    doc,
    permission,
    expense,
    reason,
    expense_type,
    role,
    dashboard,
    ticket,
    licence,
    reach,
    demo,
    request,
    kobo,
    validation_step,
    withdrawal,
    dokoun,
    applications
  }
})
