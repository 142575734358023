import * as types from './mutation-types'

export const loadAskDemos = ({ commit, dispatch, state }, {
        page, search, limit, status, from_date, to_date
    }) => {
    let url = `/get/ask_demos?page=${page}&search=${search}&limit=${limit}&status=${status}&start=${from_date}&end=${to_date}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_ASK_DEMOS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAskDemoStatus = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/ask_demos/status`).then((response) => {
            commit(types.LOAD_ASK_DEMO_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('change/' + data.id + '/ask_demo/status?status=' + data.value).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showAskDemo = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('ask_demo/' + id + '/show').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const archiveAskDemo = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('archived/' + id + '/ask_demo').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const statisticAskDemo = ({ commit, dispatch, state }, {
    page, search, limit, status, from_date, to_date
}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/ask_demos/statistiques?start=${from_date}&end=${to_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}