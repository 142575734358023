import * as types from './mutation-types'

export const loadPurchaseOrders = ({ commit, dispatch, state }, {
    page, search, limit, estimate_number, from_date, to_date, contact_id, orderByField, orderBy
}) => {
let url = `/get/purchase_orders?page=${page}&search=${search}&limit=${limit}&estimate_number=${estimate_number}
&from_date=${from_date}&to_date=${to_date}&contact_id=${contact_id}&orderByField=${orderByField}&orderBy=${orderBy}`
return new Promise((resolve, reject) => {
    window.axios.get(url).then((response) => {
        commit(types.LOAD_PURCHASE_ORDERS, response.data)
        resolve(response)
    }).catch((err) => {
        reject(err)
    })
})
}

export const showPurchaseOrder = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/' + id + '/purchase_orders').then((response) => {
            commit(types.ADD_PURCHASE_ORDER, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const generatePurchaseOrderInvoice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('generate/' + id + '/invoice').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showPurchaseOrderInvoice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/' + id + '/invoice').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}