const QuizIndex = () => import ('@/Modules/Dokoun/Quiz/views/Index')
const QuizView = () => import ('@/Modules/Dokoun/Quiz/views/View')

export default {
    path: "quizs",
    name: 'dokoun-quizs',
    children: [
        {
          path: '',
          name: 'quizs-index',
          component: QuizIndex,
        },
        {
            path: ':id/view',
            name: 'quizs-view',
            component: QuizView,
        },
    ]
}
