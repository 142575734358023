import RequestRoutes from '@/Modules/Kobo/Request/route'
import WithdrawalRoutes from '@/Modules/Kobo/Withdrawal/route'

export default {
    path: "kobo",
    name: 'kobo',
    children: [
        RequestRoutes,
        WithdrawalRoutes
    ]
}