import * as types from './mutation-types'

export const loadContactFolder = ({ commit, dispatch, state }, {
        page, search, limit, shared, userId, orderByField, orderBy
    }) => {
    let url = `docs?page=${page}&search=${search}&limit=${limit}&orderByField=${orderByField}&orderBy=${orderBy}`

    return new Promise((resolve, reject) => {
        window.crmDocAxios.get(url).then((response) => {
            commit(types.LOAD_DOCS, response.data)
            commit(types.ADD_DOCS_META, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getFolderWithFiles = ({ commit, dispatch, state }, id) => {
    let url = `docs/${id}`
    return new Promise((resolve, reject) => {
        window.crmDocAxios.get(url).then((response) => {
            commit(types.LOAD_CURRENT_FOLDER, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getFileWithUsers = ({ commit, dispatch, state }, id) => {
    let url = `docs/${id}`
    return new Promise((resolve, reject) => {
        window.crmDocAxios.get(url).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createDoc = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.crmDocAxios.post('docs',data).then((response) => {
            commit(types.ADD_DOC, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createTeam = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.crmDocAxios.post('create/team',data).then((response) => {
           // commit(types.ADD_DOC, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addUsersToTeam = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.crmDocAxios.post('add/users/on/team',data).then((response) => {
           // commit(types.ADD_DOC, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const shareDocs = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.crmDocAxios.post('docs/shared',data).then((response) => {
           // commit(types.ADD_DOC, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const getDocRoles = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.crmDocAxios.get('docs/roles').then((response) => {
           commit(types.LOAD_DOC_ROLES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const updateDoc = ({ commit, dispatch, state }, data) => {
    let url = `docs/${data.id}`
    return new Promise((resolve, reject) => {
        window.crmDocAxios.put(url).then((response) => {
            let data = {...response.data.data}
            commit(types.UPDATE_DOC, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

// TODO delete doc
export const deleteDoc = ({ commit, dispatch, state }, id) => {
    let url = `docs/${id}`
    return new Promise((resolve, reject) => {
        window.crmDocAxios.post(url).then((response) => {
            commit(types.DELETE_DOC, [id])
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}