import * as types from './mutation-types'

export default {
  [types.LOAD_ITEMS](state, items) {
    state.items = items.data
    state.meta_data = items.meta
  },
  
  [types.ADD_ITEM](state, data) {
    state.items.push(data)
  }
}
