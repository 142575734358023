import * as types from './mutation-types'

export default {
  [types.LOAD_USERS](state, users) {
    state.users = users.data
  },
  [types.LOAD_ALL_USERS](state, data) {
    state.allUsers = data.users
  },
  [types.ADD_USER](state, user) {
    state.users.push(user)
  },
  [types.ADD_USERS_META](state, users) {
    state.usersMeta = users.meta
  },
  [types.ADD_TENANT_INFO](state, data) {
    state.tenantInfo = data
  },

  [types.LOAD_TENANT](state, data) {
    state.tenant = data
  },

  [types.RESET_CURRENT_USER] (state, user) {
    state.currentUser = null
  },
  [types.BOOTSTRAP_CURRENT_USER] (state, user) {
    state.currentUser = user
  },
  [types.UPDATE_CURRENT_USER] (state, user) {
    state.currentUser = user
  },

}
