// export const getConversations = (state) => state.conversations
// export const getConversation = (state) => (id) => state.conversations[id] || {} 

export const getConversations = (state) => state.conversations
export const getUsers = (state) => state.users
export const currentUser = (state) => state.user
export const getOpenedConversation = (state) => state.openedConversations[0]
export const getConversation = (state) => (id) => state.conversations[id] || {} 


