const WithdrawalLayout = () => import('@/Modules/Kobo/Withdrawal/layout/LayoutRequest')
const WithdrawalSocietyList = () => import('@/Modules/Kobo/Withdrawal/views/Configuration/Index')

export default {
    path: "withdrawals",
    name: 'kobo-withdrawals',
    component: WithdrawalLayout,
    redirect: { name: 'kobo-withdrawals-config' },
    children: [
        {
            path: 'config',
            name: 'kobo-withdrawals-config',
            component: WithdrawalSocietyList,
        }
    ]
}