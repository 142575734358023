import * as types from './mutation-types'

export default {

  [types.LOAD_ALL_TAXES](state, taxes) {
    state.allTaxes = taxes
  },

  [types.LOAD_TAXES](state, taxes) {
    state.taxes = taxes.data
    state.metaData = taxes.meta
  },

  [types.LOAD_TAX_TYPES](state, types) {
    state.taxTypes = types
  },

  [types.ADD_TAX](state, data) {
    state.allTaxes.push(data)
    state.taxes.push(data)
  },

  [types.UPDATE_TAX] (state, data) {
    state.taxes = state.taxes.map((tax) => { if (tax.id == data.id) {tax = data} return tax })
  },

  [types.DELETE_TAX](state, taxId) {
    state.taxes = state.taxes.filter(tax => !taxId.includes(tax.id))
  }
}
