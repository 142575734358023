import * as types from './mutation-types'

export const loadExpenseTypes = ({ commit, dispatch, state }, { limit='all', page=1, search='', orderBy, orderByField="name" }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/expense_types?limit=${limit}&page=${page}&search=${search}`).then((response) => {
            commit(types.LOAD_EXPENSE_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllExpenseTypes = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/expense_types`).then((response) => {
            commit(types.LOAD_ALL_EXPENSE_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createExpenseType = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('expense_types', data).then((response) => {
            commit(types.ADD_EXPENSE_TYPE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateExpenseType = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('expense_types/' + data.id, data).then((response) => {
            commit(types.UPDATE_EXPENSE_TYPE, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showExpenseType = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expense_types/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteExpenseType = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('expense_types/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
