import store from '@/store'

export const getContactDocs = (state) => state.contactDocs
export const getRoles = (state) => state.roles

export const getCurrentFolderData = (state) => state.currentFolder

export const getContactPersonnalFolders = (state) => {
    let user = store.getters['auth/currentUser']
    return state.contactDocs.filter(doc => doc.doc_type == "FOLDER" && doc.creator.email == user.email)
}
export const getContactPersonnalFiles = (state) => {
    let user = store.getters['auth/currentUser']
    return state.contactDocs.filter(doc => doc.doc_type == "FILE" && doc.creator.email == user.email)
}

export const getContactSharedFolders = (state) => {
    let user = store.getters['auth/currentUser']
    return state.contactDocs.filter(doc => doc.doc_type == "FOLDER" && doc.creator.email != user.email)
}

export const getContactSharedFiles = (state) => {
    let user = store.getters['auth/currentUser']
    return state.contactDocs.filter(doc => doc.doc_type == "FILE" && doc.creator.email != user.email)
}


export const getContactDocsMeta = (state) => state.metaData