import * as types from './mutation-types'
import axios from "axios";

import {BASE_KOBO_SOCIETY_API_URL} from "@/Modules/Kobo/services/api";
import {BASE_KOBO_MERCHANT_API_URL} from "@/Modules/Kobo/services/api";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const loadRequests = ({ commit, dispatch, state }, {page = 1, search = '', limit ='', status= ''}) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/get/companies/datatable?page=${page}&search=${search}&limit=${limit}&status=${status}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_REQUESTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRequestById = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/get/company/${id}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_CURRENT_REQUEST, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRequestStatus = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/update/company/${data.id}/inscription/status`
    const config = getConfig(url, 'patch', data)
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.UPDATE_REQUEST_STATUS, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAccountStatus = ({ commit, dispatch, state }) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/get/company/account/status`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_ACCOUNT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadMerchantRequests = ({ commit, dispatch, state }, {page = 1, search = '', limit ='', status= ''}) => {
    let url = `${BASE_KOBO_MERCHANT_API_URL}/get/merchants?page=${page}&search=${search}&limit=${limit}&status=${status}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_MERCHANT_REQUESTS, response.data)
            console.log(response.data)
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadMerchantById = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_KOBO_MERCHANT_API_URL}/get/company/${id}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_CURRENT_MERCHANT_REQUEST, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateMerchantRequestStatus = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_KOBO_MERCHANT_API_URL}/update/company/${data.id}/inscription/status`
    const config = getConfig(url, 'patch', data)
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadMerchantAccountStatus = ({ commit, dispatch, state }) => {
    let url = `${BASE_KOBO_MERCHANT_API_URL}/crm/get/company/account/status`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_MERCHANT_ACCOUNT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}





