import * as types from './mutation-types'

export default {

  [types.LOAD_RECURRING_INVOICES](state, data) {
    state.data = data
  },

  [types.LOAD_RECURRING_STATUS](state, data) {
    state.status = data
  },

  [types.LOAD_RECURRING_FREQUENCIES](state, data) {
    state.frequencies = data
  },

  [types.LOAD_RECURRING_LIMITS](state, data) {
    state.limits = data
  }

}
