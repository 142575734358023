import * as types from './mutation-types'

export default {

  [types.LOAD_ALL_ITEMS](state, taxes) {
    state.allItems = taxes.data
  },


  [types.LOAD_ALL_CUSTOMER_ITEMS](state, data){
    state.supportItems = data
  },

  [types.LOAD_ALL_ITEMS_USED](state, data){
    state.supportItemsUsed = data
  },

  [types.LOAD_ALL_SOFTWARE_ITEMS](state, data){
    state.allSoftwareItems = data
  }


}
