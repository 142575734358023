
export const getAllItems = function (state) {
    return state.allItems
}

export const getAllSoftwareItems = (state) => state.allSoftwareItems
export const getSupportItems = (state) => state.supportItems
export const supportItemsUsed = (state) => state.supportItemsUsed





