
export const getAllContacts = function (state) {
    return state.allContacts
}

export const getContacts = function (state) {
    return state.contacts
}

export const getContactsMeta = (state) => {
    return state.contactsMeta
}


export const getSupportCustomers = (state) => state.supportContacts

