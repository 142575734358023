import * as types from './mutation-types'

export const loadReasons = ({ commit, dispatch, state }, { limit='all', page=1, search='', orderBy, orderByField="name" }) => {

    return new Promise((resolve, reject) => {
        window.axios.get(`/reasons?limit=${limit}&page=${page}&search=${search}`).then((response) => {
            commit(types.LOAD_REASONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllReasons = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/reasons`).then((response) => {
            commit(types.LOAD_ALL_REASONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createReason = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('reasons', data).then((response) => {
            commit(types.ADD_REASON, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateReason = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('reasons/' + data.id, data).then((response) => {
            commit(types.UPDATE_REASON, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showReason = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('reasons/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteReason = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('reasons/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
