import * as types from './mutation-types'

export default {
  [types.LOAD_ESTIMATES](state, estimates) {
    state.estimates = estimates.data
    state.meta_data =  estimates.meta
  },
  
  [types.ADD_ESTIMATE](state, data) {
    state.estimates.push(data)
  },

  [types.LOAD_ESTIMATE_STATUS](state, data) {
    state.status = data
  },
}
