import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Ls from './services/ls'
import HelperFunction from './services/helper'
import axios from 'axios'
import Helper from './mixins/Helper'
import Permissions from './mixins/Permissions'
import { BASE_URL } from './services/api'
import i18n from './plugins/i18n'
import crmClient from './services/crm.doc.config'
import {usePermission} from "@/composables/permissions";
import Echo from 'laravel-echo'


const {$can} = usePermission()


window.axios = axios
window.Ls = Ls
window.HelperFunction = HelperFunction
window.i18n = i18n
window.crmDocAxios = crmClient

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.baseURL = BASE_URL

window.axios.interceptors.request.use(function (config) {
    const AUTH_TOKEN = Ls.get('auth.token')
    if (AUTH_TOKEN) {
        config.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

/**
 * Global Axios Response Interceptor
 */
global.axios.interceptors.response.use(function (response) {

    return response;
}, function (err) {
    if (!err.response) {
        window.toastr.error('Network error: Please check your internet connection or wait until servers are back online')
    } else {
        if (err.response.data && (err.response.statusText === 'Unauthorized' || err.response.data === ' Unauthorized.')) {
            window.toastr.error((err.response.data.message) ? err.response.data.message : 'Unauthorized')
            // Logout
            store.dispatch('auth/logout', true)
        } else if (err.response.data && err.response.status === 404 && Ls.get('auth.token')) {

        } else if (err.response.data && err.response.status === 403) {

        } else if (err.response.data.errors) {
            // Show a notification per error
            const errors = JSON.parse(JSON.stringify(err.response.data.errors))
            for (const i in errors) {
                window.toastr.error(errors[i])
            }
        } else {
            // Unknown error
            window.toastr.error((err.response.data.message) ? err.response.data.message : 'Unknown error occurred')
        }
    }
    // Do something with request error
    // store.dispatch("changeAppLoadingStatus", false);
    return Promise.reject(err)
})

$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
    if(options.url.includes('cdn.datatables.net')){}
});

const AUTH_TOKEN = Ls.get('auth.token')

if(window.io){
    window.Echo = new Echo({
        host: `${process.env.VUE_APP_LARAVEL_ECHO_HOST}:${process.env.VUE_APP_LARAVEL_ECHO_PORT}`,
        broadcaster: 'socket.io',
        authEndpoint: `/broadcasting/auth`,
        auth: {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                'user-session-token': AUTH_TOKEN,
                'product': 'crmapp',
                'app-hostname': window.location.hostname
            }
        }
    });
}


const VueApp = createApp(App)
    .use(store)
    .use(i18n)
    .mixin(Helper)
    .mixin(Permissions)
    .use(router)
    .use(VueAxios, axios)


VueApp.directive('can', {
    bind: function (el, binding, vnode) {
        if (!$can(binding.expression.replace(/'/g, "").replace(/"/g, ""))) {
            vnode.elm.style.display = "none";
        }
    }
})


window.vueJs = VueApp.mount('#app')
