
export const getRecurringInvoicesData = function (state) {
    return state.data
}

export const getRecurringStatus = function (state) {
    return state.status
}

export const getRecurringLimit = function (state) {
    return state.limits
}

export const getRecurringFrequencies = function (state) {
    return state.frequencies
}



