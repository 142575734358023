// eslint-disable-next-line no-unused-vars

import core from "@/Modules/Kobo/store/module/core"
import deposit from "@/Modules/Kobo/Request/store/module/deposit"
import request from "@/Modules/Kobo/Request/store/module/request"


export default {
  modules: {
    core,
    deposit,
    request
  }
}
