import * as types from './mutation-types'
import {LOAD_TAX_TYPES} from "./mutation-types";

export const loadAllTaxes = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/taxes`).then((response) => {
            commit(types.LOAD_ALL_TAXES, response.data.taxes)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadTaxes = ({ commit, dispatch, state }, {
    limit = 'all', page = 1, search = '',  orderBy, orderByField="name"
    }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/taxes?limit=${limit}&page=${page}&search=${search}`).then((response) => {
            commit(types.LOAD_TAXES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })

}

export const getTaxTypes = ({ commit, dispatch, state }) =>{
    return new Promise((resolve, reject) => {
        window.axios.get('get/tax/types').then((response) => {
            commit(types.LOAD_TAX_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createTax = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('taxes', data).then((response) => {
            commit(types.ADD_TAX, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTax = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('taxes/' + data.id, data).then((response) => {
            commit(types.UPDATE_TAX, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteTax = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('taxes/' + id).then((response) => {
            commit(types.DELETE_TAX, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}