import * as types from './mutation-types'

export const loadCurrencies = ({ commit, dispatch, state }, {
    limit = 'all', page = 1, search = '',  orderBy, orderByField="name"
    }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/currencies?limit=${limit}&page=${page}&search=${search}&orderBy=${orderBy}`).then((response) => {
            commit(types.LOAD_CURRENCIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllCurrencies = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/currencies`).then((response) => {
            commit(types.LOAD_ALL_CURRENCIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAppCurrencies = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/app/currencies`).then((response) => {
            commit(types.LOAD_ALL_APP_CURRENCIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createCurrency = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('currencies', data).then((response) => {
            commit(types.ADD_CURRENCY, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadDefaultCurrency = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/default/currency`).then((response) => {
            commit(types.LOAD_DEFAULT_CURRENCY, response.data.currency)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setDefaultCurrency = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/currencies/${id}/setDefaultCurrency`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCurrency = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('currencies/' + data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showCurrency = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('currencies/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteCurrency = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('currencies/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
