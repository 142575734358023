export const LOAD_TURNOVER = 'LOAD_TURNOVER'
export const LOAD_INVOICE_STATS = 'LOAD_INVOICE_STATS'
export const LOAD_ESTIMATE_STATS = 'LOAD_ESTIMATE_STATS'
export const LOAD_PAYMENT_STATS = 'LOAD_PAYMENT_STATS'
export const LOAD_CUSTOMER_STATS = 'LOAD_CUSTOMER_STATS'
export const LOAD_EXPENSE_TYPE_STATS = 'LOAD_EXPENSE_TYPE_STATS'
export const LOAD_EXPENSE_STATS = 'LOAD_EXPENSE_STATS'
export const LOAD_TOP_CUSTOMER_STATS = 'LOAD_TOP_CUSTOMER_STATS'
export const LOAD_TOP_EXPENSE_STATS = 'LOAD_TOP_EXPENSE_STATS'
export const LOAD_TOP_PAYMENT_STATS = 'LOAD_TOP_PAYMENT_STATS'
export const LOAD_EXPENSE_CHART_STATS = 'LOAD_EXPENSE_CHART_STATS'
export const LOAD_PAYMENT_CHART_STATS = 'LOAD_PAYMENT_CHART_STATS'