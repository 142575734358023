<script>
    import {mapGetters} from "vuex";
    import { format } from 'date-fns'
    import { fr, enUS } from 'date-fns/locale'

    export default {
        computed: {
          blockUI(){
            return new KTBlockUI(document.querySelector("#kt_content"), {
              message: '<div class="blockui-message"><span class="spinner-border text-primary"></span> ' + this.$t('text_loading')+ '...</div>',
            })
          }

        },
        methods: {
            $bodyScript (src) {
                if (document.querySelector("script[src='" + src + "']")) { return }
                const script = document.createElement('script')
                script.setAttribute('src', src)
                script.setAttribute('type', 'text/javascript')
                document.body.appendChild(script)
            },

            $headerLink (href) {
                if (document.querySelector("link[href='" + href + "']")) { return }
                const link = document.createElement('link')
                link.setAttribute('href', href)
                link.setAttribute('rel', 'stylesheet')
                link.setAttribute('type', 'text/css')
                document.head.appendChild(link)
            },

            $delLink (href) {
                let el = document.querySelector("link[href='" + href + "']")
                if(el){
                    el.remove()
                }
            },

            $delScript (src) {
                let el = document.querySelector("script[src='" + src + "']");
                if(el){
                    el.remove();
                }
            },

            $goBack() {
                this.$router.go(-1)
            },

            $loading(id){
                $("#"+id).attr("data-kt-indicator","on")
                $("#"+id).attr("disabled",true)
            },

            $stopLoading(id){
                $("#"+id).attr("data-kt-indicator",false)
                $("#"+id).attr("disabled",false)
            },

            $formatNumber(n) {
                // format number 1000000 to 1 234 567
                return n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            },


            $currencyFormatEur(value) {
                return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(value)
            },

            $convertDate(date, join) {
                if (join === undefined) join = '/'
                let DateFormat = new Date(date.toDate())
                if (DateFormat) {
                    return [this.$pad(DateFormat.getDate()), this.$pad(DateFormat.getMonth() + 1), DateFormat.getFullYear()].join(join)
                }
            },

            $pad(s) {
                return (s < 10) ? '0' + s : s;
            },

            $formatDate(d) {
                var month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [day, month,year].join('/');
            },

            $formatToDate(date){
                return new Date(date).toLocaleDateString(this.$i18n.locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour : 'numeric',
                    minute : 'numeric'
                })
            },

            $formatMyDateWithoutHour(date){
                return format(new Date(date), 'dd, MMMM yyyy', {
                    locale: this.$i18n.locale === 'fr' ? fr : enUS
                })
            },

            $formatBirthdayDate(date){
                return format(new Date(date), 'dd, MMMM', {
                    locale: this.$i18n.locale === 'fr' ? fr : enUS
                })
            },


            $formatSampleMyDateWithoutHour(date){
                return format(new Date(date), 'dd-MM-yyyy', {
                    locale: this.$i18n.locale === 'fr' ? fr : enUS
                })
            },

            $formatDateShowOnlyYear(date){
                return format(new Date(date), 'yyyy', {
                    locale: this.$i18n.locale === 'fr' ? fr : enUS
                })
            },

            $formatDateWithMoment(date){
                if (date) {
                return moment(String(date)).format('YYYY/MM/DD')
                }
            },

            $startLoadingPage(){
              if(!this.blockUI?.isBlocked()){
                this.blockUI.block();
              }
            },

            $endLoadingPage(){
              if(this.blockUI?.isBlocked()){
                this.blockUI.release();
              }
            }


        }
    }
</script>
