import Ls from '../../../services/ls'
import * as types from './mutation-types'
import * as userTypes from '../user/mutation-types'
import * as rootTypes from '../../mutation-types'
import router from '../../../router/index'

export const login = ({ commit, dispatch, state }, data) => {
    const loginData = {
        email: data.email,
        password: data.password
    }
    return new Promise((resolve, reject) => {
        window.axios.post('/login', loginData).then((response) => {
            const token = response.data.access_token
            const user = response.data.user
            Ls.set('auth.token', token)

            commit(rootTypes.UPDATE_APP_LOADING_STATUS, false, { root: true })
            commit(types.AUTH_SUCCESS, {token, user})
            resolve(response)
        }).catch(err => {
            commit(types.AUTH_ERROR, err.response)
            Ls.remove('auth.token')
            reject(err)
        })
    })
}

export const getCurrentUser = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/user').then((response) => {
            commit('user/' + userTypes.UPDATE_CURRENT_USER,  response.data.user, { root: true })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const forgetPassword= ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/forgot-password', data).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const resetPassword= ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/reset-password', data).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const refreshToken = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        let data = {
            token: Ls.get('auth.token')
        }
        console.log('REFRESH ACTION')
        axios.post('/api/auth/refresh_token', data).then((response) => {
            let token = response.data.data.token
            Ls.set('auth.token', token)
            commit(types.REFRESH_SUCCESS, token)
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

export const logout = ({ commit, dispatch, state }, noRequest = false) => {
    if (noRequest) {
        commit(types.AUTH_LOGOUT)
        Ls.remove('auth.token')
        router.push('/login')

        return true
    }

    return new Promise((resolve, reject) => {
        axios.post('logout').then((response) => {
            commit(types.AUTH_LOGOUT)
            Ls.remove('auth.token')
            router.push('/login')
            window.toastr['success']('Logged out!', 'Success')
        }).catch(err => {
            reject(err)
            commit(types.AUTH_LOGOUT)
            Ls.remove('auth.token')
            router.push('/login')
        })
    })
}





