import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'


const initialState = {
  turnover: [],
  invoice_stats: [],
  estimates_stats: [],
  payments_stats: [],
  customers_stats: [],
  expense_types_stats: [],
  expenses_stats: [],
  customer_top_stats: [],
  expenses_top_stats: [],
  payments_top_stats: [],
  expenses_charts_stats_amounts: [],
  expenses_charts_stats_months: [],
  payments_charts_stats_amounts: [],
  payments_charts_stats_months: []
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
