import * as types from './mutation-types'

export const loadCustomerStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/customers/stats`).then((response) => {
            commit(types.LOAD_CUSTOMER_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadEstimateStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/estimates/stats`).then((response) => {
            commit(types.LOAD_ESTIMATE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpenseStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/expenses/stats`).then((response) => {
            commit(types.LOAD_EXPENSE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpenseTypeStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/expense_types/stats`).then((response) => {
            commit(types.LOAD_EXPENSE_TYPE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadInvoiceStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/invoices/stats`).then((response) => {
            commit(types.LOAD_INVOICE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/payments/stats`).then((response) => {
            commit(types.LOAD_PAYMENT_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadTurnoverStats = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/turnover`).then((response) => {
            commit(types.LOAD_TURNOVER, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCustomersTopStats = ({ commit, dispatch, state }, limit) => {
    let url = `/get/top/customers?limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_TOP_CUSTOMER_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpensesTopStats = ({ commit, dispatch, state }, limit) => {
    let url = `/get/top/expenses?limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_TOP_EXPENSE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadPaymentsTopStats = ({ commit, dispatch, state }, limit) => {
    let url = `/get/top/payments?limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_TOP_PAYMENT_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpensesChartData = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/expenses/chart/stats').then((response) => {
            commit(types.LOAD_EXPENSE_CHART_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentsChartData = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/payments/chart/stats').then((response) => {
            commit(types.LOAD_PAYMENT_CHART_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}