import * as types from './mutation-types'

export default {

  [types.LOAD_INVOICES](state, data) {
    state.data = data
  },

  [types.LOAD_ALL_INVOICES](state, data) {
    state.invoices = data?.invoices
  },

  [types.LOAD_INVOICE_STATUS](state, data) {
    state.status = data
  },

  [types.LOAD_INVOICE_PAID_STATUS](state, data) {
    state.paidStatus = data
  }

}
