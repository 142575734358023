import * as types from './mutation-types'

export default {
  [types.LOAD_EXPENSES](state, expenses) {
    state.expenses = expenses.data
    state.meta_data =  expenses.meta
  },
  
  [types.ADD_EXPENSE](state, data) {
    state.expenses.push(data)
  },

  [types.LOAD_EXPENSE_STATUS](state, data) {
    state.status = data
  },

  [types.LOAD_USER_EXPENSE_STATS](state, user_expense_stats) {
    state.user_expense_stats = user_expense_stats
  },

  [types.LOAD_ADMIN_EXPENSE_STATS](state, admin_expense_stats) {
    state.admin_expense_stats = admin_expense_stats
  },

  [types.LOAD_USER_EXPENSES](state, users) {
    state.user_expenses = users.data
    state.user_meta_data =  users.meta
  },

  [types.LOAD_OFFICE_EXPENSE_STATS](state, office_expense_stats) {
    state.office_expense_stats = office_expense_stats
  },


  [types.LOAD_USERS_STATS](state, users_stats) {
    state.users_stats = users_stats
  },

  [types.LOAD_EXPENSES_STATS](state, expenses_stats) {
    state.expenses_stats = expenses_stats
  },

  [types.LOAD_EXPENSES_CHARTS](state, expenses_charts) {
    state.expenses_charts_amount = expenses_charts.amounts
    state.expenses_charts_months = expenses_charts.months
  },
}
