import * as types from './mutation-types'
import {BASE_DOKOUN_API_URL} from "@/Modules/Dokoun/services/api";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const categories = ({ commit, dispatch, state }, {page, search, limit}) => {
    let url = `${BASE_DOKOUN_API_URL}/get/paginate/categories/collection?page=${page}&search=${search}&limit=${limit}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            commit(types.ADD_CATEGORIES, response.data)
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCategories = ({ commit, dispatch, state }) => {
    let url = `${BASE_DOKOUN_API_URL}/get/categories/collection`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            commit(types.ADD_CATEGORIES, response.data)
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const category = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_DOKOUN_API_URL}/show/${id}/category`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeCategory = ({ commit, dispatch, state }, data) => {
    let formData = new FormData()
    formData.append('name', data.name);
    formData.append('image', data.image);
  
    formData.append('_method', 'POST');
    let url = `${BASE_DOKOUN_API_URL}/store/category`
    return new Promise((resolve, reject) => {
        window.axios.post(url, formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCategory = ({ commit, dispatch, state }, data) => {
    let formData = new FormData()
    formData.append('name', data.name);
    formData.append('image', data.image);
  
    formData.append('_method', 'PATCH');
    let url = `${BASE_DOKOUN_API_URL}/update/${data.id}/category`    
    return new Promise((resolve, reject) => {
        window.axios.post(url, formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteCategory = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/delete/${data.id}/category`
    const config = getConfig(url, 'delete', data)
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}