export const getTurnover = (state) => state.turnover
export const getInvoiceStats = (state) => state.invoice_stats
export const getEstimateStats = (state) => state.estimates_stats
export const getPaymentStats = (state) => state.payments_stats
export const getCustomerStats = (state) => state.customers_stats
export const getExpenseTypeStats = (state) => state.expense_types_stats
export const getExpenseStats = (state) => state.expenses_stats
export const getCustomerTopStats = (state) => state.customer_top_stats
export const getExpenseToStats = (state) => state.expenses_top_stats
export const getPaymentTopStats = (state) => state.payments_top_stats
export const getExpensesChartStatsAmounts = (state) => state.expenses_charts_stats_amounts
export const getExpensesChartStatsMonths = (state) => state.expenses_charts_stats_months
export const getPaymentsChartStatsAmounts = (state) => state.payments_charts_stats_amounts
export const getPaymentsChartStatsMonths = (state) => state.payments_charts_stats_months