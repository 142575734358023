const LicenceIndex = () => import ('@/Modules/Licence/views/Index')
const LicenceView = () => import ('@/Modules/Licence/views/View')
/*const TicketNew = () => import ('@/Modules/Ticket/views/New')
const TicketView = () => import ('@/Modules/Ticket/views/View')
const TicketCustomersConfigurationView = () => import ('@/Modules/Ticket/views/TicketCustomersConfigurationView')
const TicketCategoriesConfiguration = () => import ('@/Modules/Ticket/views/TicketCategoriesConfiguration')
const TicketConfigurations = () => import ('@/Modules/Ticket/views/Configurations')*/

export default {
    path: "licences",
    name: 'licences',
    children: [
        {
          path: '',
          name: 'licences-index',
          component: LicenceIndex,
          //meta: {permission: [ ["view_payment"]] },
        },
        {
            path: ':id/view',
            name: 'licences-view',
            component: LicenceView,
            //meta: {permission: [ ["view_payment"]] },
        },
        /*{
          path: 'new',
          name: 'tickets-new',
          component: TicketNew,
          //meta: {permission: [ ["view_payment"]] },
        },
        {
          path: ':id/view',
          name: 'tickets-view',
          component: TicketView,
          //meta: {permission: [ ["view_payment"]] },
        },
        {
            path: 'configurations',
            name: 'tickets-configurations',
            component: TicketConfigurations,
            children: [
                {
                    path: 'customers/configurations',
                    name: 'tickets-customers-configurations',
                    component: TicketCustomersConfigurationView,
                    //meta: {permission: [ ["view_payment"]] },
                },
                {
                    path: 'categories/configurations',
                    name: 'tickets-categories-configurations',
                    component: TicketCategoriesConfiguration,
                    //meta: {permission: [ ["view_payment"]] },
                },
            ]
        },*/
    ]
}
