import * as types from './mutation-types'

export default {
  [types.LOAD_PURCHASE_ORDERS](state, purchase_orders) {
    state.purchase_orders = purchase_orders.data
    state.meta_data = purchase_orders.meta
  },
  
  [types.ADD_PURCHASE_ORDER](state, data) {
    state.purchase_orders.push(data)
  }
}
