import * as types from './mutation-types'

const notification = new Audio('/assets/audio/notification.mp3')

const notificationRing = () => {
  notification.currentTime = 0
  notification.play()
}

const addMessage = (state, {conversation_id, message}) => {
  let conversation = state.conversations[conversation_id] || {message: []}
    conversation.list_order_by_date = message.created_at
    conversation.messages.push(message)
    // if(!message.is_me){
    //   conversation.unread = (conversation.unread || 0) + 1
    // }
    state.conversations = {...state.conversations, ...{[conversation_id]: conversation}}
}

const incrementUnread = (state, conversation_id) => {
  let conversation = state.conversations[conversation_id]
  if(conversation){
    conversation.unread++
  }
}

const joinConversationChannel = (state, id) => {
  if(window.Echo){
    Echo.join(`App.Conversation.${id}`)
    .here((users) => {
      users.forEach((e) => {
        let conversation = state.conversations[id] || {messages: []}
        if(!conversation.is_group && e.id === conversation?.peer?.id){
          let peer = conversation.peer || {}
          peer.online = true
          conversation.peer = peer
        }else{
          conversation.participators = conversation.participators.map((participator) => {
            if(participator.participator_id === e.id){
              participator.online = true
            }
            return participator
          })
        }
        state.conversations = {...state.conversations, [id]: conversation}
      })
    })
    .joining((e) => {
      let conversation = state.conversations[id] || {messages: []}
      if(!conversation.is_group && e.id === conversation?.peer?.id){
        let peer = conversation.peer || {}
        peer.online = true
        conversation.peer = peer
      }else{
        conversation.participators = conversation.participators.map((participator) => {
          if(participator.participator_id === e.id){
            participator.online = true
          }
          return participator
        })
      }
      state.conversations = {...state.conversations, [id]: conversation}
    })
    .listen('.Messager\\v1\\Chat\\Events\\NewMessageEvent', function(e){
      let message = e.message
      message.is_me = false
      addMessage(state, {
        conversation_id: message.conversation_id,
        message: message
      })
      
      if(!state.openedConversations.includes(e.message.conversation_id) || document.hidden){
        notificationRing()
        incrementUnread(state, e.message.conversation_id)
      }
    })
  }
}

const addConversationChannel = (state, user_id) => {
  if(window.Echo){
    Echo.join(`App.New.Group.${user_id}`)
    .listen('.Messager\\v1\\Chat\\Events\\NewGroupEvent', function(e){
      let c = e.conversation
      joinConversationChannel(state, c.id)
      let conversation = state.conversations[c.id] || {messages: []}
      conversation = {...conversation, ...c}
      state.conversations = {...state.conversations, [c.id]: conversation}
    })
  }
}

export default {
  [types.ADD_CONVERSATIONS](state, {conversations, user}) {
    conversations.forEach((c) => {
      joinConversationChannel(state, c.id)
      let conversation = state.conversations[c.id] || {messages: []}
      conversation = {...conversation, ...c}
      state.conversations = {...state.conversations, [c.id]: conversation}
    })
    addConversationChannel(state, user.user_id)
  },

  [types.ADD_CONVERSATION](state, c) {
    joinConversationChannel(state, c.id)
    let conversation = state.conversations[c.id] || {messages: []}
    conversation = {...conversation, ...c}
    state.conversations = {...state.conversations, [c.id]: conversation}
  },

  [types.ADD_MESSAGES](state, {conversation_id, messages}) {
    let conversation = state.conversations[conversation_id] || {}
    conversation.messages = messages
    conversation.loaded = true
    state.conversations = {...state.conversations, ...{[conversation_id]: conversation}}
  },

  [types.ADD_MESSAGE](state, {conversation_id, message}) {
    addMessage(state, {conversation_id, message})
  },

  [types.OPEN_CONVERSATION]: function(state, id){
    state.openedConversations = [id]
  },

  [types.RESET_OPEN_CONVERSATION]: function(state){
    state.openedConversations = []
  },

  [types.MARK_CONVERSATION_AS_READ]: function(state, conversation_id){
    let conversation = state.conversations[conversation_id] || {messages: []}
    conversation.unread = 0
    state.conversations = {...state.conversations, ...{[conversation_id]: conversation}}
  },

  

  
}


