import * as types from './mutation-types'

export const loadRecurringInvoices = ({ commit, dispatch, state }, {
    page, search, limit, status, customer_id,from_date,to_date, orderByField, orderBy
}) => {
    let url = `/recurring?page=${page}&search=${search}&limit=${limit}&status=${status}&from_date=${from_date}&to_date=${to_date}&customer_id=${customer_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_RECURRING_INVOICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurring = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/${id}`).then((response) => {
            //commit(types.LOAD_INVOICE_PAID_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/status`).then((response) => {
            commit(types.LOAD_RECURRING_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringFrequency = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/frequencies`).then((response) => {
            commit(types.LOAD_RECURRING_FREQUENCIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringLimit = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/limits`).then((response) => {
            commit(types.LOAD_RECURRING_LIMITS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/recurring', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`/recurring/${data.id}`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeRecurringStatus = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/change/status/${id}`).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/recurring/delete', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
