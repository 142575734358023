import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'


const initialState = {
  users: [],
  allUsers: [],
  usersMeta: {},
  tenantInfo: {},
  tenant: {},
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
