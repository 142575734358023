import * as types from './mutation-types'

export default {
  [types.LOAD_TURNOVER](state, turnover) {
    state.turnover = turnover
  },

  [types.LOAD_INVOICE_STATS](state, invoice_stats) {
    state.invoice_stats = invoice_stats
  },

  [types.LOAD_ESTIMATE_STATS](state, estimates_stats) {
    state.estimates_stats = estimates_stats
  },

  [types.LOAD_PAYMENT_STATS](state, payments_stats) {
    state.payments_stats = payments_stats
  },

  [types.LOAD_CUSTOMER_STATS](state, customers_stats) {
    state.customers_stats = customers_stats
  },

  [types.LOAD_EXPENSE_TYPE_STATS](state, expense_types_stats) {
    state.expense_types_stats = expense_types_stats
  },

  [types.LOAD_EXPENSE_STATS](state, expenses_stats) {
    state.expenses_stats = expenses_stats
  },

  [types.LOAD_TOP_CUSTOMER_STATS](state, customer_top_stats) {
    state.customer_top_stats = customer_top_stats
  },

  [types.LOAD_TOP_EXPENSE_STATS](state, expenses_top_stats) {
    state.expenses_top_stats = expenses_top_stats
  },

  [types.LOAD_TOP_PAYMENT_STATS](state, payments_top_stats) {
    state.payments_top_stats = payments_top_stats
  },

  [types.LOAD_EXPENSE_CHART_STATS](state, expenses_charts_stats) {
    state.expenses_charts_stats_amounts = expenses_charts_stats.amounts
    state.expenses_charts_stats_months = expenses_charts_stats.months
  },

  [types.LOAD_PAYMENT_CHART_STATS](state, payments_charts_stats) {
    state.payments_charts_stats_amounts = payments_charts_stats.amounts
    state.payments_charts_stats_months = payments_charts_stats.months
  },
}
