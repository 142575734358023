export const getRequests = (state) => state.requests
export const getRequest = (state) => state.currentRequest
export const getRequestsMeta = (state) => state.meta_data
export const getAccountStatus = (state) => state.accountStatus


export const getMerchantRequests = (state) => state.requestMerchants
// export const getMerchantDetails = (state) => (id) => state.requestMerchants.find(x => x.id === id) || {}
export const getMerchantDetails = (state) => state.currentMerchantRequest
export const getMerchantRequestsMeta = (state) => state.meta_data
export const getMerchantAccountStatus = (state) => state.merchantAccountStatus
