export const LOAD_EXPENSES = 'LOAD_EXPENSES'
export const ADD_EXPENSE = 'ADD_EXPENSE'
export const LOAD_EXPENSE_STATUS = 'LOAD_EXPENSE_STATUS'
export const LOAD_USER_EXPENSE_STATS = 'LOAD_USER_EXPENSE_STATS'
export const LOAD_ADMIN_EXPENSE_STATS = 'LOAD_ADMIN_EXPENSE_STATS'
export const LOAD_USER_EXPENSES = 'LOAD_USER_EXPENSES'
export const LOAD_OFFICE_EXPENSE_STATS = "LOAD_OFFICE_EXPENSE_STATS"
export const LOAD_USERS_STATS = 'LOAD_USERS_STATS'
export const LOAD_EXPENSES_STATS = 'LOAD_EXPENSES_STATS'
export const LOAD_EXPENSES_CHARTS = 'LOAD_EXPENSES_CHARTS'
