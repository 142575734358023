import * as types from './mutation-types'

export default {
  [types.LOAD_REACHES](state, reaches) {
    state.reaches = reaches.data
    state.meta_data =  reaches.meta
  },

  [types.LOAD_REACH_STATUS](state, data) {
    state.status = data
  },
}
