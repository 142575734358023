import * as types from './mutation-types'

export const loadExpenses = ({ commit, dispatch, state }, {user_id, page, search, limit, status, created_at, reason_id, 
    expense_type_id, orderByField, orderBy}) => {
    let url = `/expenses?page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    if(user_id){
        url = `/expenses?user_id=${user_id}&page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
        &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    }
    
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_EXPENSES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUserExpenses = ({ commit, dispatch, state }, {user_id, page, search, limit, status, created_at, reason_id, 
    expense_type_id, orderByField, orderBy}) => {

    let url = `/get/users/have_expenses?page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`

    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_USER_EXPENSES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createExpense = ({ commit, dispatch, state }, data) => {
    let formData = new FormData();
    formData.append('expense_type_id', data.expense_type_id);
    formData.append('title', data.title);
    data.fees.forEach((content, key) =>  {
        formData.append("fees["+ content.id +"][reason_id]", content.reason_id)
        formData.append("fees["+ content.id +"][currency_id]", content.currency_id)
        formData.append("fees["+ content.id +"][pay_type_id]", content.pay_type_id) 
        formData.append("fees["+ content.id +"][amount]", content.amount)
        formData.append("fees["+ content.id +"][date]", content.date)
        formData.append("fees["+ content.id +"][exchange_rate]", content.exchange_rate)
        content.invoices.forEach((invoice, k) => {
            formData.append("fees["+ content.id +"][invoices]["+ k +"]", invoice)
        })
    })
    formData.append('_method', 'POST');

    return new Promise((resolve, reject) => {
        window.axios.post('expenses', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            commit(types.ADD_EXPENSE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateExpense = ({ commit, dispatch, state }, data) => {
    let formData = new FormData();
    
    formData.append('expense_type_id', data.expense_type_id);
    formData.append('title', data.title);
    data.fees.forEach((content, key) =>  {
        formData.append("fees["+ content.id +"][reason_id]", content.reason_id)
        formData.append("fees["+ content.id +"][currency_id]", content.currency_id)
        formData.append("fees["+ content.id +"][pay_type_id]", content.pay_type_id) 
        formData.append("fees["+ content.id +"][amount]", content.amount)
        formData.append("fees["+ content.id +"][date]", content.date)
        formData.append("fees["+ content.id +"][exchange_rate]", content.exchange_rate)
        content.invoices.forEach((invoice, k) => {
            formData.append("fees["+ content.id +"][invoices]["+ k +"]", invoice)
        })
    })

    formData.append('_method', 'PATCH');

    return new Promise((resolve, reject) => {
        window.axios.post('expenses/' + data.id, formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showExpense = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expenses/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpenseStatus = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`expenses/status`).then((response) => {
            commit(types.LOAD_EXPENSE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteExpense = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('expenses/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendExpense = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send/expense/' + data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validateExpense = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('validate/expense/' + data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const rejectExpense = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('reject/expense/' + data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUserExpenseStats = ({ commit, dispatch, state },{
    user_id, page, search, limit, status, created_at, reason_id, expense_type_id, orderByField, orderBy}) => {
    let url = `/get/user/expenses/status/statistiques?page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    if(user_id){
        url = `/get/user/expenses/status/statistiques?user_id=${user_id}&page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
        &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    }
    
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_USER_EXPENSE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAdminExpenseStats = ({ commit, dispatch, state }, {
    page, search, limit, status, created_at, reason_id, expense_type_id, orderByField, orderBy
}) => {
    let url = `/get/admin/expenses/status/statistiques?page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_ADMIN_EXPENSE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadOfficeExpenseStats = ({ commit, dispatch, state }, {
    user_id, page, search, limit, status, created_at, reason_id, expense_type_id, orderByField, orderBy
}) => {
    let url = `/get/user/expenses/status/statistiques?user_id=office&page=${page}&search=${search}&limit=${limit}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_OFFICE_EXPENSE_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUsersStats = ({ commit, dispatch, state }, {page, search, limit, limit_user, limit_expense, status, created_at, reason_id, 
    expense_type_id, orderByField, orderBy}) => {
    let url = `/get/users/statistiques?page=${page}&search=${search}&limit=${limit_user}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_USERS_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpensesStats = ({ commit, dispatch, state }, {page, search, limit, limit_user, limit_expense, status, created_at, reason_id, 
    expense_type_id, orderByField, orderBy}) => {
    let url = `/get/expenses/statistiques?page=${page}&search=${search}&limit=${limit_expense}&status=${status}&created_at=${created_at}
    &reason_id=${reason_id}&expense_type_id=${expense_type_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_EXPENSES_STATS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpensesChartData = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/expenses/chart/data').then((response) => {
            commit(types.LOAD_EXPENSES_CHARTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}