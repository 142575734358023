import * as types from './mutation-types'
import {UPDATE_REQUEST_STATUS} from "./mutation-types";

export default {

  [types.LOAD_REQUESTS](state, requests) {
    state.requests = requests.data
    state.meta_data = requests.meta
  },
  [types.LOAD_CURRENT_REQUEST](state, data) {
    state.currentRequest = {
      company: data.company || {},
      country: data.country || {},
      documents: data.documents || []
    }
  },
  [types.LOAD_ACCOUNT_STATUS] (state, status) {
    state.accountStatus = status
  },
  [types.UPDATE_REQUEST_STATUS] (state, data) {
    state.currentRequest.company.account_status =  data.status
  },
  [types.LOAD_MERCHANT_REQUESTS](state, data) {
    state.requestMerchants = data.data
  },
  [types.LOAD_MERCHANT_ACCOUNT_STATUS](state, accountStatus) {
    state.merchantAccountStatus = accountStatus
  },
  [types.LOAD_CURRENT_MERCHANT_REQUEST](state, data) {
    state.currentMerchantRequest = {
      company: data.company || {},
      country: data.country || {},
      documents: data.documents || [],
      account_types: data.accountTypes || []
    }
  },

}
