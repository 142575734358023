import * as types from './mutation-types'
import Ls from '@/services/ls'

const getFetchHeader = function(){
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    'user-session-token': Ls.get('auth.token'),
    'product': 'crmapp',
    'app-hostname': window.location.hostname,
  }
} 

export const loadCurrentUser = async ({ commit, dispatch, state}) => {
  let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + '/api/v1/user', {
    method: 'GET',
    headers: getFetchHeader()
  })

  if(response.ok){
    let json = await response.json();
    commit(types.ADD_CURRENT_USER, json)
  }else{
    let error = await response.json()
  }
}

export const loadConversations = async ({ commit, dispatch, state}) => {
  let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + '/api/v1/chat/conversations', {
    method: 'GET',
    headers: getFetchHeader()
  })

  if(response.ok){
    let json = await response.json();
    commit(types.ADD_CONVERSATIONS, {
      conversations: json.data,
      user: json.meta.user
    })
  }else{
    let error = await response.json()
  }
}

export const loadUsers = async ({ commit, dispatch, state}) => {
  let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + '/api/v1/users', {
    method: 'GET',
    headers: getFetchHeader()
  })

  if(response.ok){
    let json = await response.json();
    commit(types.ADD_CONVERSATIONS_USERS, json.users)
  }else{
    let error = await response.json()
  }
}

export const createConversation = ({ commit, dispatch, state }, {type, data}) => {
  if(type === 'direct'){
    return new Promise(async (resolve, reject) => {
      let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + '/api/v1/chat/conversations', {
        method: 'POST',
        headers: getFetchHeader(),
        body: JSON.stringify(data)
      })

      if(response.ok){
        let json = await response.json();
        commit(types.ADD_CONVERSATION, json.data)
        resolve(json)
      }else{
        let error = await response.json()
        reject(error)
      }
    })
  }

  if(type === 'group'){
    return new Promise(async (resolve, reject) => {
      let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + '/api/v1/chat/group/conversations', {
        method: 'POST',
        headers: getFetchHeader(),
        body: JSON.stringify(data)
      })

      if(response.ok){
        let json = await response.json();
        commit(types.ADD_CONVERSATION, json.data)
        resolve(json)
      }else{
        let error = await response.json()
        reject(error)
      }
    })
  }
}



export const sendAudioMessage = ({ commit, dispatch, state}, { conversation_id, data }) => {
  return new Promise((resolve, reject) => {
    window.axios.post(process.env.VUE_APP_MESSAGER_HOST + `/api/v1/chat/conversations/${conversation_id}/messages/audio`, 
      data,{headers: {
        'Content-type': 'multipart/form-data',
        'user-session-token': Ls.get('auth.token'),
        'product': 'crmapp',
        'app-hostname': window.location.hostname === 'localhost' ? 'app.crmapp.local' : window.location.hostname,
      }})
      .then((response) => {
        commit(types.ADD_MESSAGE, {
          conversation_id,
          message: response.data.data
        })
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const sendMessage = ({ commit, dispatch, state}, { conversation_id, data }) => {

  let fetchHeader = getFetchHeader()

  if(window.Echo){
    fetchHeader['x-socket-id'] =  window.Echo ? Echo.socketId() : ''
  }

  return new Promise(async (resolve, reject) => {

    let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + `/api/v1/chat/conversations/${conversation_id}/messages`, {
      method: 'POST',
      headers: fetchHeader,
      body: JSON.stringify(data)
    })

    if(response.ok){
      let json = await response.json();
      commit(types.ADD_MESSAGE, {
        conversation_id,
        message: json.data
      })
      resolve(json)
    }else{
      let error = await response.json()
      reject(error)
    }
  })
}

export const loadMessages = ({ commit, dispatch, state}, conversation_id) => {
  commit(types.OPEN_CONVERSATION, conversation_id)

  return new Promise(async (resolve, reject) => {
    let response = await fetch(process.env.VUE_APP_MESSAGER_HOST + `/api/v1/chat/conversations/${conversation_id}/messages`, {
      method: 'GET',
      headers: getFetchHeader(),
    })

    if(response.ok){
      let json = await response.json();
      commit(types.ADD_MESSAGES, {
        conversation_id,
        messages: json.data
      })
      commit(types.MARK_CONVERSATION_AS_READ, conversation_id)
      resolve(json)
    }else{
      let error = await response.json()
      reject(error)
    }
  })
}

export const writing = ({ commit, dispatch, state}, {id, name}) => {
  Echo.private(`App.Conversation.${id}`)
    .whisper('typing', {
      name
    })
  ;
}



