import * as types from './mutation-types'

export const loadAllItems = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/items`).then((response) => {
            commit(types.LOAD_ALL_ITEMS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllSoftwareItems = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/software/items`).then((response) => {
            commit(types.LOAD_ALL_SOFTWARE_ITEMS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}