import * as types from './mutation-types'

export default {

  [types.ADD_LICENCES](state, licences) {
    state.licences = licences.data
    state.meta_data = licences.meta
  },

  [types.ADD_LICENCES_TYPE](state, licencesType) {
    state.licencesType = licencesType
  },

  [types.ADD_LICENCES_DURATION_TYPE](state, licencesDurationType) {
    state.licencesDurationType = licencesDurationType
  },
}
