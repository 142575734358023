
export const getInvoicesData = function (state) {
    return state.data
}

export const getInvoices = function (state) {
    return state.invoices
}

export const getInvoiceStatus = function (state) {
    return state.status
}

export const getInvoicePaidStatus = function (state) {
    return state.paidStatus
}


