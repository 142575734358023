export const currentUser = (state) => state.currentUser

export const getUsers = (state) => state.users

export const getAllUsers =  (state) => {
    return state.allUsers
}

export const getUsersMeta = (state) => {
    return state.usersMeta
}

export const getTenantInfo = (state) => {
    return state.tenantInfo
}
export const getTenantInformation = (state) => {
    return state.tenant
}



