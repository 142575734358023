import * as types from './mutation-types'
import {BASE_DOKOUN_API_URL} from "@/Modules/Dokoun/services/api";

export const questions = ({ commit, dispatch, state }, {page, search, limit}) => {
    let url = `${BASE_DOKOUN_API_URL}/questions?page=${page}&search=${search}&limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_QUIZS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const question = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_DOKOUN_API_URL}/question/${id}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeQuestion = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/store/questions`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeManyQuestion = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/store/many/questions`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeAnswer = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/store/answers`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateQuestion = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/update/question/${data.id}`
    return new Promise((resolve, reject) => {
        window.axios.put(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateQuiz = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/update/quiz`
    return new Promise((resolve, reject) => {
        window.axios.put(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAnswer = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/update/answer/${data.id}`
    return new Promise((resolve, reject) => {
        window.axios.put(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteQuestion = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_DOKOUN_API_URL}/delete/question/${id}`
    return new Promise((resolve, reject) => {
        window.axios.delete(url).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAnswer = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_DOKOUN_API_URL}/delete/answer/${id}`
    return new Promise((resolve, reject) => {
        window.axios.delete(url).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const importQuestions = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/import/questions`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}