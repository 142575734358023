import * as types from './mutation-types'

import {BASE_KOBO_PAYMENT_API_URL, BASE_KOBO_SOCIETY_API_URL} from "@/Modules/Kobo/services/api";
import axios from "axios";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const loadDepositStatus = ({ commit, dispatch, state }) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/get-deposit-status`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_DEPOSIT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadDeposits = ({ commit, dispatch, state }, {page = 1, search = '', limit ='', status= ''}) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/deposits?page=${page}&search=${search}&limit=${limit}&status=${status}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_DEPOSITS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const updateDepositStatus = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/change-deposit-status/${data.id}`
    const config = getConfig(url, 'put', data)
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            // commit(types.UPDATE_REQUEST_STATUS, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


