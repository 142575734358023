import * as types from './mutation-types'

export default {
  [types.LOAD_UNITS](state, units) {
    state.units = units.data
    state.meta_data = units.meta
  },

  [types.LOAD_ALL_UNITS](state, units) {
    state.all_units = units.data
  },
  
  [types.ADD_UNIT](state, data) {
    state.units.push(data)
  },

  [types.UPDATE_UNIT] (state, data) {
    state.units = state.units.map((unit) => { if (unit.id == data.id) {unit = data} return unit })
  },

  [types.DELETE_UNIT](state, unitId) {
    state.units = state.units.filter(unit => !unitId.includes(unit.id))
  }

}
