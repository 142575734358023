import * as types from './mutation-types'

export const loadInvoices = ({ commit, dispatch, state }, {
        page, search, limit, status, paid_status, invoice_number, from_date, to_date, contact_id, orderByField, orderBy
    }) => {
    let url = `/invoices?page=${page}&search=${search}&limit=${limit}&status=${status}&paid_status=${paid_status}
    &invoice_number=${invoice_number}&from_date=${from_date}&to_date=${to_date}&contact_id=${contact_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_INVOICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllInvoice = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/invoices`).then((response) => {
            commit(types.LOAD_ALL_INVOICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadInvoiceStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/invoice/status`).then((response) => {
            commit(types.LOAD_INVOICE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadInvoicePaidStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/invoice/paid/status`).then((response) => {
            commit(types.LOAD_INVOICE_PAID_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/invoices', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/invoices/${data.id}/send`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveInvoiceCreditNote = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/invoices/${id}/creditNote`).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`/invoices/${data.id}`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/invoices/delete', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadInvoice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/invoices/${id}`).then((response) => {
            //commit(types.LOAD_INVOICE_PAID_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const loadRecurringInvoices = ({ commit, dispatch, state }, {
    page, search, limit, status, customer_id,from_date,to_date, orderByField, orderBy
}) => {
    let url = `/recurring?page=${page}&search=${search}&limit=${limit}&status=${status}&from_date=${from_date}&to_date=${to_date}&customer_id=${customer_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            //commit(types.LOAD_RECURRING_INVOICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/status`).then((response) => {
            //commit(types.LOAD_RECURRING_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadRecurringFrequency = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/recurring/frequencies`).then((response) => {
            //commit(types.LOAD_RECURRING_FREQUENCIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/recurring', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`/recurring/${data.id}`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteRecurringInvoice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/invoices/delete', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
