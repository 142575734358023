
import * as types from './mutation-types'
import {attachPermissionsToRole} from "@/store/modules/role/actions";

export const loadPermissions = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/permissions').then((response) => {
            commit(types.ADD_PERMISSIONS, response.data.permissions)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPermissionsWithData = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/get/permissionsWithData').then((response) => {
            commit(types.ADD_PERMISSIONS_WITH_DATA, response.data.permissions)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const attachPermissionsToUser = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('permissions/user', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

