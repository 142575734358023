const ConseilIndex = () => import ('@/Modules/Dokoun/Conseils/views/Index')

export default {
    path: "conseils",
    name: 'dokoun-conseils',
    redirect: { name: 'conseils-index' },
    children: [
        {
            path: '',
            name: 'conseils-index',
            component: ConseilIndex,
        }
    ]
}
