import * as types from './mutation-types'

export const loadLicences = ({ commit, dispatch, state }, {page, search, limit}) => {
    let url = `/licences?page=${page}&search=${search}&limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_LICENCES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadLicenceDetail = ({ commit, dispatch, state }, {licence, page, search, limit}) => {
    let url = `/licence/history/${licence}?page=${page}&search=${search}&limit=${limit}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadLicence = ({ commit, dispatch, state }, id) => {
    let url = `/licences/${id}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            //commit(types.ADD_LICENCES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeLicence = ({ commit, dispatch, state }, data) => {
    let url = `/licences`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            //commit(types.ADD_LICENCES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setLicenceInvoice = ({ commit, dispatch, state }, id) => {
    let url = `set/licences/${id}/invoices`
    return new Promise((resolve, reject) => {
        window.axios.post(url).then((response) => {
            //commit(types.ADD_LICENCES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateLicence = ({ commit, dispatch, state }, data) => {
    let url = `/licences/${data.id}`
    return new Promise((resolve, reject) => {
        window.axios.patch(url, data).then((response) => {
            //commit(types.ADD_LICENCES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadLicencesDurationType = ({ commit, dispatch, state }) => {
    let url = `get/licences/duration_types`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_LICENCES_DURATION_TYPE, response.data.licencesType)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadLicencesType = ({ commit, dispatch, state }) => {
    let url = `get/licences/types`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_LICENCES_TYPE, response.data.licencesType)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}




