import * as types from './mutation-types'

export default {
    [types.LOAD_COUNTRIES] (state, countries) {
        state.countries = countries
    },
    [types.LOAD_GENDERS] (state, genders) {
        state.genders = genders
    },
    [types.LOAD_PIECES] (state, pieces) {
            state.pieces = pieces
    },
    [types.LOAD_AGES] (state, ages) {
        state.ages = ages
    },

}
