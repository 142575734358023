import * as types from './mutation-types'

export const loadPaginateTickets = ({ commit, dispatch, state }, {page, search, category_tickets, ticket_status, customer_priority}) => {
    let url = `admin/get/tickets?page=${page}`

    if(search){
        url = url + `&search=${search}`
    }

    if(category_tickets){
        url = url + `&category_tickets=${category_tickets}`
    }

    if(ticket_status){
        url = url + `&ticket_status=${ticket_status}`
    }

    if(customer_priority){
        url = url + `&customer_priority=${customer_priority}`
    }

    return new Promise((resolve, reject) => {
        window.axios.get(url)
            .then((response) => {
                commit(types.LOAD_PAGINATE_TICKETS, response.data)
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
    })
}

export const loadTicket = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`admin/get/${id}/tickets`).then((response) => {
            commit(types.ADD_TICKET, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTicket = ({ commit, dispatch, state }, {id, data}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`admin/update/${id}/tickets`, data).then((response) => {
            //commit(types.ADD_TICKET, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCategoryTickets = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
      window.axios.get(`admin/get/category-tickets`).then((response) => {
        commit(types.ADD_CATEGORY_TICKETS, response.data.data)
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
}

export const loadApplicationCategoryTickets = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`admin/get/application/${id}/category-tickets`).then((response) => {
            //commit(types.ADD_CATEGORY_TICKETS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setCustomerProduct = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`admin/supports/set-customer-product`, data).then((response) => {
            //commit(types.ADD_CATEGORY_TICKETS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getCustomerProduct = ({ commit, dispatch, state }, customer) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`admin/supports/${customer}/customer-product`).then((response) => {
            commit(types.ADD_CONTACT_PRODUCTS, {
                customer: customer,
                data: response.data.customerProducts
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createCategory = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        axios.post(`admin/create/ticket/category`, data)
            .then((response) => {
                commit(types.ADD_CATEGORY_TICKET, response.data.data)
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const loadCategoryStatus = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        axios.post(`admin/get/ticket/${id}/category/status`)
            .then((response) => {
                commit(types.ADD_CATEGORY_TICKET_STATUS, {
                    id,
                    data: response.data.categoryStatus
                })
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const setCategoryStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        axios.post(`admin/set/ticket/category/status`, data)
            .then((response) => {
                commit(types.CHANGE_CATEGORY_PRODUCT_STATUS, data)
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const createTicket = ({ commit, dispatch, state }, data) => {

    let formData = new FormData()
    formData.append('_method', 'POST');
    formData.append('category_ticket_id', data.category_ticket_id )
    formData.append('customer_priority', data.customer_priority )
    formData.append('description', data.description )
    formData.append('object', data.object )
    formData.append('contact_id', data.contact_id)
    formData.append('application_url', data.application_url)
    formData.append('user[email]', data.user.email)
    formData.append('user[id]', data.user.id)
    formData.append('user[name]', data.user.name)
    if(data.ticket_id){
        formData.append('ticket_id', data.ticket_id)
    }
    data.files.forEach((file, key) => {
        formData.append('files[' + key + ']', file);
    })


    return new Promise((resolve, reject) => {
        axios.post(`admin/create/ticket`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })

}

export const getApplicationUsers = async ({ commit, dispatch, state }, url) => {
    let fullUrl = url + '/api/support/get/users'
    return await fetch(fullUrl, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'user-session-token': Ls.get('auth.token')
        }
    })
}

export const supportBootstrap = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`admin/supports/bootstrap`).then((response) => {
            commit(types.ADD_CATEGORY_TICKETS, response.data.categoryTickets)
            commit(types.ADD_TICKETS_STATUS, response.data.ticketsStatus)
            commit(types.ADD_CUSTOMER_PRIORITIES, response.data.customerPriorities)
            commit(types.ADD_TICKETS_CONTACTS, response.data.contacts)
            commit('contact/LOAD_ALL_CUSTOMER', response.data.customers, {root: true})
            commit('item/LOAD_ALL_CUSTOMER_ITEMS', response.data.products, {root: true})
            commit('item/LOAD_ALL_CUSTOMER_ITEMS', response.data.products, {root: true})
            commit('item/LOAD_ALL_ITEMS_USED', response.data.productUsed, {root: true})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}






