import * as types from './mutation-types'

export default {
  [types.LOAD_ASK_DEMOS](state, ask_demos) {
    state.ask_demos = ask_demos.data
    state.meta_data =  ask_demos.meta
  },

  [types.LOAD_ASK_DEMO_STATUS](state, data) {
    state.status = data
  },
}
