import * as types from './mutation-types'

export default {
    [types.LOAD_DOCS]: (state, docs) => {
        state.contactDocs = docs.data
    },

    [types.LOAD_DOC_ROLES]: (state, data) => {
        state.roles = data.roles
    },

    [types.LOAD_CURRENT_FOLDER]: (state, folder) => {
        state.currentFolder = folder.data
    },

    [types.ADD_DOCS_META]:(state, docs) => {
    state.metaData = docs.meta
    },

    [types.ADD_DOC]:(state, doc) => {
        state.contactDocs.push(doc.data)
    },

    [types.UPDATE_DOC]:(state, data) => {
        state.contactDocs = state.contactDocs.map((doc) => { if (doc.id == data.id) { doc = data } return doc })
    },

    [types.DELETE_DOC]:(state, docIds) => {
        state.contactDocs = state.contactDocs.filter((doc) => !docIds.includes(doc.id) )
    },
}
