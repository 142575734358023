export const getCompanyValidationStep = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/validation/step/${data.companyId}/${data.type}/${data.companyName}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateValidationStepStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put(`update/validation/step/status`, data)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setValidationStepStatus = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.put(`set/validation/step/status/${id}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}