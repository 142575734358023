export const getExpenses = (state) => state.expenses
export const getExpensesMeta = (state) => state.meta_data
export const getExpenseStatus = (state) => state.status
export const getUserExpenseStats = (state) => state.user_expense_stats
export const getAdminExpenseStats = (state) => state.admin_expense_stats
export const getUserExpenses = (state) => state.user_expenses
export const getUserExpensesMeta = (state) => state.user_meta_data
export const getOfficeExpenseStats = (state) => state.office_expense_stats
export const getUsersStats = (state) => state.users_stats
export const getExpensesStats = (state) => state.expenses_stats
export const getExpensesChartAmounts = (state) => state.expenses_charts_amount
export const getExpensesChartMonths = (state) => state.expenses_charts_months