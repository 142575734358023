
export const ticketPaginateMeta = (state) => state.ticketPaginateMeta
export const getPaginateTickets = (state) => (page) => state.ticketsPaginate[page] || []
export const categoryTickets = (state) => state.categoryTickets
export const ticketsStatus = (state) => state.ticketsStatus
export const customerPriorities = (state) => state.customerPriorities
export const contactsTickets = (state) => state.contactsTickets
export const getContactProducts = (state) => (contact) => state.contactProducts[contact] || []
export const getTicket = (state) => (ticket) => state.tickets.find(x => x.id === ticket) || {}
export const categoryStatus = (state) => (id) => (state.categoryTickets.find(x => x.id === id))?.status || {}


