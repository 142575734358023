// eslint-disable-next-line no-unused-vars

import advices from "@/Modules/Dokoun/Conseils/store"
import category from "@/Modules/Dokoun/Category/store"
import quiz from "@/Modules/Dokoun/Quiz/store"

export default {
  modules: {
    advices,
    category,
    quiz
  }
}
