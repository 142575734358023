import * as types from './mutation-types'

export default {
    [types.LOAD_DEPOSIT_STATUS] (state, status) {
        state.depositStatus = status
    },
    [types.LOAD_DEPOSITS](state, deposits) {
        state.deposits = deposits
    },
    [types.UPDATE_DEPOSIT_STATUS](state, deposits) {
        state.deposits = deposits
    },
}
