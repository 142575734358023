import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import TicketModule from '@/Modules/Ticket/route'
import LicenceModule from '@/Modules/Licence/route'
import KoboModule from '@/Modules/Kobo/route'
import DokounModule from '@/Modules/Dokoun/route'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  showSpinner: false
})


const LayoutLoginView = () => import('../views/Auth/LayoutLogin.vue')
const LoginView = () => import('../views/Auth/Login.vue')
const ForgotPasswordView = () => import('../views/Auth/ForgotPassword.vue')
const ResetPasswordView = () => import('../views/Auth/ResetPassword.vue')

const LayoutBasicView = () => import('@/views/Layouts/LayoutBasic')
const DashboardView = () => import("@/views/Dashboard/Dashboard.vue")

const LayoutProfilSettingView = () => import('@/views/Settings/Profils/Index')
const ProfilSettingView = () => import('@/views/Settings/Profils/Info')

const ContactIndexView = () => import('@/views/Contact/index')
const ContactDetailView = () => import('@/views/Contact/Details')
const ContactNewView = () => import('@/views/Contact/new')
const ContactEditView = () => import('@/views/Contact/edit')

const EstimatesIndexView = () => import('@/views/Estimates/index')
const EstimatesNewView = () => import('@/views/Estimates/new')
const EstimatesShowView = () => import('@/views/Estimates/show')
const EstimatesEditView = () => import('@/views/Estimates/edit')

const InvoicesIndexView = () => import('@/views/Invoices/index')
const InvoicesNewView = () => import('@/views/Invoices/new')
const InvoicesShowView = () => import('@/views/Invoices/show')
const InvoicesEditView = () => import('@/views/Invoices/edit')

const InvoicesRecurrentIndexView = () => import('@/views/InvoicesRecurrent/index')
const InvoicesRecurrentNewView = () => import('@/views/InvoicesRecurrent/new')
const InvoicesRecurrentShowView = () => import('@/views/InvoicesRecurrent/show')
const InvoicesRecurrentEditView = () => import('@/views/InvoicesRecurrent/edit')

const ExpensesIndexView = () => import('@/views/Expenses/index')
const ExpensesNewView = () => import('@/views/Expenses/new')
const ExpensesShowView = () => import('@/views/Expenses/show')
const ExpensesEditView = () => import('@/views/Expenses/edit')

const ExpensesAdminIndexView = () => import('@/views/ExpensesAdmin/index')
const ExpensesAdminShowView = () => import('@/views/ExpensesAdmin/show')
const ExpensesAdminStatisticsView = () => import('@/views/ExpensesAdmin/statistics')
const ExpensesAdminShowUserView = () => import('@/views/ExpensesAdmin/user')

const PaymentIndexView = () => import('@/views/Payments/index')
const PaymentNewView = () => import('@/views/Payments/new')
const PaymentShowView = () => import('@/views/Payments/show')
const PaymentFactureView = () => import('@/views/Payments/factureAcompte')
const PaymentEditView = () => import('@/views/Payments/edit')


const LayoutSettingView = () => import('@/views/Settings/layout/Index')
const RoleSettingView  = () => import ('../views/Settings/User/Role/Role')
const PermissionSettingView = () => import ('../views/Settings/User/Role/Permissions')

const IndexUserView = () => import ('../views/Settings/User/index')
const CreateUserView = () => import ('../views/Settings/User/create')
const EditUserView = () => import ('../views/Settings/User/edit')

const PurchaseOrderIndexView = () => import('@/views/PurchaseOrders/index')
const PurchaseOrderShowView = () => import('@/views/PurchaseOrders/show')

const DemandContactIndexView = () => import('@/views/Demand/Contacts/index')
const DemandDemoIndexView = () => import('@/views/Demand/Demos/index')

const ErrorPageView = () => import('@/views/Error404')

const routes = [
  {
    path: '/',
    component: LayoutLoginView,
    children: [
      {
        path: '/',
        component: LoginView,
        meta: { redirectIfAuthenticated: true },
      },
      {
        path: 'login',
        name: 'login',
        component: LoginView,
        meta: { redirectIfAuthenticated: true },
      },
      {
        path: '/forgot-password',
        component:  ForgotPasswordView ,
        name: 'forgot-password',
        meta: { redirectIfAuthenticated: true },
      },
      {
        path: '/reset-password/',
        component: ResetPasswordView ,
        name: 'reset-password',
        meta: { redirectIfAuthenticated: true },
      },
    ]

  },
  /*
  |--------------------------------------------------------------------------
  | Admin Backend Routes
  |--------------------------------------------------------------------------|
  */
  {
    path: '/admin',
    component: LayoutBasicView, // Change the desired Layout here
    meta: { requiresAuth: true },
    children: [
      // Dashbord
      {
        path: '',
        component: DashboardView,
        name: 'dashboard',
      },
      {
        path: 'dashboard',
        component:  DashboardView,
        name: 'dashboard-path',
      },

      // contact
      {
        path: 'contacts',
        name: 'contact-index',
        component: ContactIndexView
      },
      {
        path: 'contacts/details/:id',
        name: 'contacts-details',
        component: ContactDetailView
      },
      {
        path: 'contacts/new',
        name: 'contacts-new',
        component: ContactNewView
      },
      {
        path: 'contacts/:id/edit',
        name: 'contacts-edit',
        component: ContactEditView
      },
      // Items
      {
        path: 'items',
        name: 'items-index',
        component: () => import('@/views/Items/index'),
        meta: {permission: [ ["view_item"]] },
      },

      //Support
      TicketModule,
      LicenceModule,
      KoboModule,
      DokounModule,

      // Payment
      {
        path: "payments",
        name: 'payments',
        children: [
          {
            path: '',
            name: 'payments-index',
            component: PaymentIndexView,
            meta: {permission: [ ["view_payment"]] },

          },
          {
            path: 'payments/:id',
            name: 'payments-show',
            component: PaymentShowView,
            meta: {permission: [ ["view_payment"]] },
          },
          {
            path: 'payments/:id/facture',
            name: 'payments-facture',
            component: PaymentFactureView,
            meta: {permission: [ ["view_payment"]] },
          },
          {
            path: 'payments/new',
            name: 'payments-new',
            component: PaymentNewView,
            meta: {permission: [ ["create_payment"]] },

          },
          {
            path: 'payments/:id/edit',
            name: 'payments-edit',
            component: PaymentEditView,
            meta: {permission: [ ["update_payment"]] },
          },
        ]
      },

      // Estimates
      {
        path: 'estimates',
        name: 'estimates',
        children: [
          {
            path: '',
            name: 'estimates-index',
            component: EstimatesIndexView,
            meta: {permission: [ ["view_estimate"]] },
          },
          {
            path: 'new',
            name: 'estimates-new',
            component: EstimatesNewView,
            meta: {permission: [ ["create_estimate"]] },
          },
          {
            path: ':id/show',
            name: 'estimates-show',
            component: EstimatesShowView,
            meta: {permission: [ ["view_estimate"]] },
          },
          {
            path: ':id/edit',
            name: 'estimates-edit',
            component: EstimatesEditView,
            meta: {permission: [ ["update_estimate"]] },
          },

          //Purchase Order
          {
            path: 'purchase/orders',
            name: 'purchase_orders-index',
            component: PurchaseOrderIndexView,
            meta: {permission: [ ["view_purchase_order"]] },
          },
          {
            path: 'purchase/orders/:id/view',
            name: 'purchase_orders-show',
            component: PurchaseOrderShowView,
            meta: {permission: [ ["view_purchase_order"]] },
          },
        ]
      },

      // Invoices
      {
        path: 'invoices',
        children: [
          {
            path: '',
            name: 'invoices-index',
            component: InvoicesIndexView
          },
          {
            path: ':id/show',
            name: 'invoices-show',
            component: InvoicesShowView
          },
          {
            path: 'new',
            name: 'invoices-new',
            component: InvoicesNewView
          },
          {
            path: ':id/edit',
            name: 'invoices-edit',
            component: InvoicesEditView
          },
            // recurring
          {
            path: 'recurring',
            name: 'recurring-index',
            component: InvoicesRecurrentIndexView
          },
          {
            path: 'recurring/:id',
            name: 'recurring-invoices-show',
            component: InvoicesRecurrentShowView
          },
          {
            path: 'recurring/new',
            name: 'recurring-invoices-new',
            component: InvoicesRecurrentNewView
          },
          {
            path: 'recurring/:id/edit',
            name: 'recurring-invoices-edit',
            component: InvoicesRecurrentEditView
          },
        ]
      },

      {
        path: 'expenses',
        children: [
          // Expenses
          {
            path: '',
            name: 'expenses-index',
            component: ExpensesIndexView,
            meta: {permission: [ ["view_expense"]] },
          },
          {
            path: ':id/new',
            name: 'expenses-new',
            component: ExpensesNewView,
            meta: {permission: [ ["create_expense"]] },
          },
          {
            path: ':id/show',
            name: 'expenses-show',
            component: ExpensesShowView,
            meta: {permission: [ ["view_expense"]] },
          },
          {
            path: ':id/edit',
            name: 'expenses-edit',
            component: ExpensesEditView,
            meta: {permission: [ ["update_expense"]] },
          },
          // ExpensesAdmin
          {
            path: 'validations',
            name: 'validations-index',
            component: ExpensesAdminIndexView,
            meta: {permission: [ ["view_users_expense"]] },
          },
          {
            path: 'validations/user/:id',
            name: 'validations-show-user',
            component: ExpensesAdminShowUserView,
            meta: {permission: [ ["view_users_expense"]] },
          },
          {
            path: 'validations/statistics',
            name: 'validations-statistics',
            component: ExpensesAdminStatisticsView,
            meta: {permission: [ ["view_all_stats_expense"]] },
          },
          {
            path: 'validations/:id',
            name: 'validations-show',
            component: ExpensesAdminShowView,
            meta: {permission: [ ["view_users_expense"]] },
          },
        ]
      },

      //Demand
      {
        path: 'demand',
        children: [
          // Contacts
          {
            path: 'contacts',
            name: 'demand-contacts-index',
            component: DemandContactIndexView,
            meta: {permission: [ ["view_expense"]] },
          },
          // Démos
          {
            path: 'demos',
            name: 'demand-demos-index',
            component: DemandDemoIndexView,
            meta: {permission: [ ["view_expense"]] },
          }
        ]
      },

      //conversations
      {
        path: 'conversations',
        name: 'conversations-index',
        component: () =>import('@/views/Conversations/Index'),
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'conversations-welcome',
            component: () =>import('@/views/Conversations/Welcome'),
            meta: { requiresAuth: true },
          },
          {
            path: ':id/messages',
            name: 'conversations-messages',
            component: () =>import('@/views/Conversations/Messaging'),
            meta: { requiresAuth: true },
          },
        ]
      },

      // Docs
      {
        path: 'docs',
        component: () => import('@/views/Docs/Layout/index'),
        children: [
          {
            path: '',
            name: 'docs-index',
            component: () => import('@/views/Docs/index')
          },
          {
            path: 'share',
            name: 'docs-share',
            component: () => import('@/views/Docs/share')
          },
          {
            path: 'folder/:id',
            name: 'docs-folder',
            component: () => import('@/views/Docs/folder')
          },
          {
            path: 'other',
            name: 'docs-other',
            component: () => import('@/views/Docs/other')
          }
        ]
      },
      {
        path: 'folder/:id',
        name: 'docs-folder-file',
        component: () => import('@/views/Docs/file')
      },

      //settings
      {
        path: 'settings',
        name: 'setting-index',
        children: [
          {
            path: 'users',
            name: 'setting-users',
            component: () => import('@/views/Settings/User/Layout/LayoutUser'),
            children: [
              {
                path: '',
                name: 'setting-users-path',
                component: IndexUserView
              },
              {
                path: 'roles',
                name: 'setting-role',
                component: RoleSettingView
              },
            ]
          },
          // User
          {
            path: 'user/create',
            name: 'user-create',
            component: CreateUserView,
          },
          {
            path: 'user/update/:id',
            name: 'user-edit',
            component: EditUserView,
          },
          {
            path: 'rights/:id/view',
            name: 'setting-role-details',
            component: PermissionSettingView,
          },
          {
            path: 'profile',
            name: 'profile-user',
            component: LayoutProfilSettingView,
            children: [
              {
                path: '',
                name: 'setting-user-profile',
                component: ProfilSettingView
              },
              {
                path: 'tenant',
                name: 'setting-user-tenant',
                component: () => import('@/views/Settings/Profils/Tenant')
              }
            ]
          },
          {
            path: 'tools',
            name: 'setting-tools',
            component: () => import('@/views/Settings/Tools/Layout/LayoutTools'),
            children: [
              {
                path: 'units',
                name: 'setting-tools-units',
                component: () => import('@/views/Settings/Tools/unit')
              },
              {
                path: 'tax',
                name: 'setting-tools-tax',
                component: () => import('@/views/Settings/Tools/tax')
              },
              {
                path: 'currency',
                name: 'setting-tools-currency',
                component: () => import('@/views/Settings/Tools/currency')
              },
              {
                path: 'expense',
                name: 'setting-tools-expense',
                component: () => import('@/views/Settings/Tools/expense')
              },

            ]
          },
        ]
      }
    ]
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: ErrorPageView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (typeof to.matched[0]?.components.default === 'function'  || typeof to.matched[0]?.components.default === 'object') {
    if(window.vueJs){
      window.vueJs.$emit('asyncComponentLoading', to) // Start progress bar
    }
  }

  if (to.matched.some(m => m.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      return next('/login')
    }
  }

  if (to.matched.some(m => m.meta.redirectIfAuthenticated) && store.getters['auth/isAuthenticated']) {
    return next('/admin/dashboard')
  }

  let meta_permission = to.matched[to.matched.length - 1].meta.permission

  if (meta_permission) {
    let hasAccess = false;
    for(let permission of meta_permission ){
      for (let perm of permission) {
        if (!hasAccess) {
          await axios.get(`/show/${perm}/permission`)
              .then(() => {
                hasAccess = true
              })
              .catch(() => {
                hasAccess = false
              })
        }
      }
    }
    if (!hasAccess) {
      next(`/admin/error/403`)
    }
  }

  return next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
