import * as types from './mutation-types'

export default {

  [types.LOAD_PAYMENTS](state, data) {
    state.data = data
  },

  [types.LOAD_PAYMENT_STATUS](state, data) {
    state.status = data
  },

  [types.LOAD_PAYMENT_TYPES](state, data) {
    state.types = data?.data
  }

}
