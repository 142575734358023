export const ADD_CONVERSATIONS = 'ADD_CONVERSATIONS'
export const ADD_CONVERSATION = 'ADD_CONVERSATION'
export const ADD_CONVERSATIONS_USERS = 'ADD_CONVERSATIONS_USERS'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const OPEN_CONVERSATION = 'OPEN_CONVERSATION'
export const RESET_OPEN_CONVERSATION = 'RESET_OPEN_CONVERSATION'
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ'
export const ADD_CURRENT_USER = 'ADD_CURRENT_USER'

