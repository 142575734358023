const CategoryIndex = () => import ('@/Modules/Dokoun/Category/views/Index')

export default {
    path: "categories",
    name: 'dokoun-categories',
    redirect: { name: 'conseil-category-index' },
    children: [
        {
            path: '',
            name: 'conseil-category-index',
            component: CategoryIndex,
        }
    ]
}
