
import axios from 'axios'
import Ls from './ls'
const DOC_API_BASE_URL = `http://docs.localhost/v1/api`
const APP_HOSTNAME = 'http://' + window.location.host
const PRODUCT_NAME = "crmapp"
const config = {
  baseURL: DOC_API_BASE_URL,
  headers: {
    'user-session-token': Ls.get('auth.token'),
    'product': PRODUCT_NAME,
    'app-hostname': APP_HOSTNAME,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
};
const crmClient = axios.create(config)
export default crmClient