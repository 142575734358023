import * as types from './mutation-types'


export default {

  [types.LOAD_COUNTRIES](state, countries) {
    state.countries = countries.data
  },

}
