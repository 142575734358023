<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('permission', [
      'permissions'
    ])
  },

  methods: {
    $can(permissionName) {
      if(this.permissions && this.permissions.length){
        return this.permissions.indexOf(permissionName) !== -1
      }
      return false
    },

    $canAccessMenu(permissionNames){
      if(this.permissions && this.permissions.length){
        let access = false
        if(permissionNames.length === 0){
          access = true
        }
        for(let perm of permissionNames){
          if(!access){
            access = this.permissions.indexOf(perm) !== -1
          }
        }
        return access
      }
      return false
    }
  }
}
</script>
