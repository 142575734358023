import * as types from './mutation-types'

import {BASE_KOBO_SOCIETY_API_URL} from "@/Modules/Kobo/services/api";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const loadCountries = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/get/countries`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_COUNTRIES, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadGenders = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_KOBO_SOCIETY_API_URL}/get/gender`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            console.log(response)
            commit(types.LOAD_GENDERS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

