import * as types from './mutation-types'

export default {
  [types.LOAD_EXPENSE_TYPES](state, expense_types) {
    state.expense_types = expense_types.data
    state.meta_data = expense_types.meta
  },

  [types.LOAD_ALL_EXPENSE_TYPES](state, expense_types) {
    state.all_expense_types = expense_types.data
  },
  
  [types.ADD_EXPENSE_TYPE](state, data) {
    state.expense_types.push(data)
  },

  [types.UPDATE_EXPENSE_TYPE] (state, data) {
    state.expense_types = state.expense_types.map((expense_type) => { if (expense_type.id == data.id) {expense_type = data} return expense_type })
  },

  [types.DELETE_EXPENSE_TYPE](state, expense_type_id) {
    state.expense_types = state.expense_types.filter(expense_type => !expense_type_id.includes(expense_type.id))
  }
}
