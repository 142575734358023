import * as types from './mutation-types'

export default {
    [types.LOAD_ROLES](state, roles) {
        state.roles = roles.data
        state.metaData = roles.meta
    },

    [types.LOAD_ALL_ROLES](state, roles) {
        state.allRoles = roles.data
    },

    [types.ADD_ROLE](state, data) {
        state.roles.push(data.data)
    },

    [types.UPDATE_ROLE] (state, data) {
        state.roles = state.roles.map((role) => { if (role.id == data.id) {role = data} return role })
    },

    [types.DELETE_ROLE](state, roleId) {
        state.roles = state.roles.filter(role => roleId != role.id)
    }

}
