import * as types from './mutation-types'

export default {
  [types.LOAD_REASONS](state, reasons) {
    state.reasons = reasons.data
    state.meta_data = reasons.meta
  },

  [types.LOAD_ALL_REASONS](state, reasons) {
    state.all_reasons = reasons.data
  },
  
  [types.ADD_REASON](state, data) {
    state.reasons.push(data)
  },

  [types.UPDATE_REASON] (state, data) {
    state.reasons = state.reasons.map((reason) => { if (reason.id == data.id) {reason = data} return reason })
  },

  [types.DELETE_REASON](state, reasonId) {
    state.reasons = state.reasons.filter(reason => !reasonId.includes(reason.id))
  }

}
