import * as types from './mutation-types'

export default {

  [types.LOAD_ALL_CONTACTS](state, contacts) {
    state.allContacts = contacts.data
  },
  [types.LOAD_ALL_CUSTOMER](state, customers) {
    state.supportContacts = customers
  },
  [types.LOAD_CONTACTS](state, contacts) {
    state.contacts = contacts.data
  },
  [types.ADD_CONTACT](state, data) {
    state.contacts.unshift(data)
  },
  [types.SET_AVATAR] (state, {id, avatar}) {
    state.contacts = state.contacts.map((contact) => { if (contact.id == id) {contact.avatar = avatar} return contact })
  },
  [types.UPDATE_CONTACT] (state, data) {
    state.contacts = state.contacts.map((contact) => { if (contact.id == data.id) {contact = data} return contact })
  },
  [types.ADD_NEXT_CONTACTS] (state, data) {
    state.contacts = [...state.contacts, ...data.contacts]
  },
  [types.ADD_CONTACTS_META](state, contacts) {
    state.contactsMeta = contacts.meta
  },
  [types.DELETE_CONTACT](state, contactIds) {
    state.contacts = state.contacts.filter(contact => !contactIds.includes(contact.id))
  }

}
