import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import {getLicencesDurationType} from "./getters";


const initialState = {
  licences: [],
  meta_data: [],
  getLicencesDurationType: [],
  licencesType: []
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
