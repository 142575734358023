import * as types from './mutation-types'
import axios from "axios";
import {BASE_KOBO_PAYMENT_API_URL} from "@/Modules/Kobo/services/api";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const getWithdrawalPlanner = ({ commit, dispatch, state }) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/get/withdrawal/planner`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const configWithdrawalPlanner = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/store/withdrawal/planner`
    const config = getConfig(url, 'post', data)
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadWithdrawalPlannerEnums = ({ commit, dispatch, state }) => {
    let url = `${BASE_KOBO_PAYMENT_API_URL}/get/withdrawal/planner/enums`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
            commit(types.LOAD_WITHDRAWAL_PLANNER_ENUMS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}