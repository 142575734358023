import * as types from './mutation-types'

export const loadReaches = ({ commit, dispatch, state }, {
        page, search, limit, status, from_date, to_date
    }) => {
    let url = `/get/reaches?page=${page}&search=${search}&limit=${limit}&status=${status}&start=${from_date}&end=${to_date}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_REACHES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadReachStatus = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/reaches/status`).then((response) => {
            commit(types.LOAD_REACH_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('change/' + data.id + '/reach/status?status=' + data.value).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showReach = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('reach/' + id + '/show').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const archiveReach = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('archived/' + id + '/reach').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const statisticReach = ({ commit, dispatch, state }, {
    page, search, limit, status, from_date, to_date
}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/reaches/statistiques?start=${from_date}&end=${to_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}