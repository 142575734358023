import * as types from './mutation-types'

export const loadUsers = ({ commit, dispatch, state }, {page=1, search = '', limit = '5'}) => {
  let url = `/users?page=${page}&search=${search}&limit=${limit}`
  return new Promise((resolve, reject) => {
    window.axios.get(url).then((response) => {
      commit(types.LOAD_USERS, response.data)
      commit(types.ADD_USERS_META, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadUsersPage = ({ commit }, page) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/users?limit=5&page=${page}`)
        .then(response => {
          commit(types.LOAD_USERS, response.data)
          commit(types.ADD_USERS_META, response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
  })
}
export const searchUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/users?display_name=${data}`).then((response) => {
      commit(types.LOAD_USERS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getUsers = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`all/users`).then((response) => {
      commit(types.LOAD_ALL_USERS, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const getUserById = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/users/${id}` ).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const checkPassword = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/check-password', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const createUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/users', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/users/${data.id}`, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateUserPassword = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/user/${data.id}/password`, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/users/delete', data).then((response) => {
      resolve(response)
      this.loadUsers({ commit, dispatch, state });
    }).catch((err) => {
      reject(err)
    })
  })
}

export const showTenantInformation = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
      window.axios.get('show/tenant/information').then((response) => {
        commit(types.ADD_TENANT_INFO, response.data)
        commit(types.LOAD_TENANT, response.data)
        resolve(response)
        resolve(response.data)
      }).catch((err) => {
          reject(err)
      })
  })
}

export const updateTenantInfo = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put('/tenant/update', data).then((response) => {
      commit(types.ADD_TENANT_INFO, response.data)
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}