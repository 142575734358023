export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'
export const BOOTSTRAP_CURRENT_USER = 'BOOTSTRAP_CURRENT_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_TENANT = 'LOAD_TENANT'
export const ADD_USER = 'ADD_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const ADD_USERS_META = 'ADD_USERS_META'
export const ADD_TENANT_INFO = 'ADD_TENANT_INFO'
export const LOAD_ALL_USERS = 'LOAD_ALL_USERS'
