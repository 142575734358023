import * as types from './mutation-types'

export const loadAllContacts = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/contacts`).then((response) => {
            commit(types.LOAD_ALL_CONTACTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadContacts = ({ commit, dispatch, state },
                                {limit='all',
                                 page=1,
                                 search='',
                                 orderBy,
                                 orderByField}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts?limit=${limit}&page=${page}&search=${search}&orderBy=${orderBy}&orderByField=${orderByField}`)
            .then((response) => {
                commit(types.LOAD_CONTACTS, response.data)
                commit(types.ADD_CONTACTS_META, response.data)
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
    })
}

export const loadContactsPage = ({ commit }, page) => {
    return new Promise((resolve, reject) => {
        console.log(page)
        window.axios.get(`/contacts?limit=5&page=${page}`)
            .then(response => {
                commit(types.LOAD_CONTACTS, response.data)
                commit(types.ADD_CONTACTS_META, response.data)
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const searchContacts = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts?search=${data}`).then((response) => {
            commit(types.LOAD_CONTACTS, response.data)
            commit(types.ADD_CONTACTS_META, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAllContactsByType = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts?${data}`).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContactById = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts/${id}` ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContactByContactId = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts?limit=all&contact_id=${id}`).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addStaffs = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/contacts/addStaffs`, data).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createContact = ({ commit, dispatch, state }, data) => {
    let contactData = formatData(data)
    let formData = new FormData()
    for (const [key, value] of Object.entries(contactData)) {
        formData.append(key, value);
    }

    if(data.staffs && data.staffs.length) {
        data.staffs.forEach((staff, index) => {
            formData.append(`staffs[${index}]`, staff);
        });
    }

    if(data.addresses && data.addresses.length) {
        data.addresses.forEach((address, index) => {
            for (const [key, value] of Object.entries(address)) {
                formData.append(`addresses[${index}][${key}]`, value);
            }
        });
    }
  
    formData.append('_method', 'POST');
    return new Promise((resolve, reject) => {
        window.axios.post('/contacts', formData).then((response) => {
            commit(types.ADD_CONTACT, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateContact = ({ commit, dispatch, state }, data) => {

    data = {...data}
    let contactData = formatData(data)
    let formData = new FormData()
    for (const [key, value] of Object.entries(contactData)) {
        formData.append(key, value);
    }

    if(data.staffs && data.staffs.length) {
        data.staffs.forEach((staff, index) => {
            formData.append(`staffs[${index}]`, staff);
        });
    }

    data.addresses.forEach((address, index) => {
        for (const [key, value] of Object.entries(address)) {
            formData.append(`addresses[${index}][${key}]`, value);
        }
    });
    formData.append('_method', 'PUT');
    return new Promise((resolve, reject) => {
        window.axios.post(`/contacts/${data.id}`, formData).then((response) => {
            commit(types.UPDATE_CONTACT, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteContact = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/contacts/delete', data).then((response) => {
            commit(types.DELETE_CONTACT, data.ids)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

function formatData(data) {
    const contactData = {};
    console.log("######################## DATA")
    if (data.name !== null && data.name !== undefined) {
        contactData['name'] = data.name;
    }
    if (data.contact_type !== null && data.contact_type !== undefined) {
        contactData['contact_type'] = data.contact_type;
    }
    if (data.email !== null && data.email !== undefined) {
        contactData['email'] = data.email;
    }
    if (data.phone !== null && data.phone !== undefined) {
        contactData['phone'] = data.phone;
    }
    if (data.website !== null && data.website !== undefined) {
        contactData['website'] = data.website;
    }
    if (data.enable_portal !== null && data.enable_portal !== undefined) {
        contactData['enable_portal'] = JSON.parse(data.enable_portal);
    }
    if (data.customer !== null && data.customer !== undefined) {
        contactData['customer'] = JSON.parse(data.customer);
    }
    if (data.supplier !== null && data.supplier !== undefined) {
        contactData['supplier'] = JSON.parse(data.supplier);
    }
    if (data.influencer !== null && data.influencer !== undefined) {
        contactData['influencer'] = JSON.parse(data.influencer);
    }
    if (data.position_held !== null && data.position_held !== undefined) {
        contactData['position_held'] = data.position_held;
    }
    if (data.prospect !== null && data.prospect !== undefined) {
        contactData['prospect'] = JSON.parse(data.prospect);
    }
    if (data.contact_id !== null && data.contact_id !== undefined) {
        contactData['contact_id'] = data.contact_id;
    }
    if (data.status !== null && data.status !== undefined) {
        contactData['status'] = data.status;
    }
    if (data.contact_created_at !== null &&  data.contact_created_at !== undefined) {
        contactData['contact_created_at'] = data.contact_created_at;
    }
    if (data.staff_born_at !== null &&  data.staff_born_at !== undefined) {
        contactData['staff_born_at'] = data.staff_born_at;
    }
    if (data.currency_id !== null &&  data.currency_id !== undefined) {
        contactData['currency_id'] = data.currency_id;
    }
    if (data.avatar !== null &&  data.avatar !== undefined && data.avatar instanceof File) {
        contactData['avatar'] = data.avatar;
    }



    return contactData;
}

export const getContactInvoicingCount = ({ commit, dispatch, state }, { id, startDate, endDate }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts/${id}/invoicing-count?startDate=${startDate}&endDate=${endDate}` ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContactChartData = ({ commit, dispatch, state }, { id, startDate, endDate }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts/${id}/invoicing-chartData?startDate=${startDate}&endDate=${endDate}`, ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContactTopInvoices = ({ commit, dispatch, state }, { id, startDate, endDate, limit = 5 }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts/${id}/top-invoices?startDate=${startDate}&endDate=${endDate}&limit=${limit}`, ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getContactTopItems = ({ commit, dispatch, state }, { id, startDate, endDate, limit = 5 }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/contacts/${id}/top-items?startDate=${startDate}&endDate=${endDate}&limit=${limit}`, ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}





