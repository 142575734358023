import * as types from './mutation-types'

export default {

  [types.LOAD_PAGINATE_TICKETS](state, ticketsPaginate) {
    let tickets = state.ticketsPaginate[ticketsPaginate.meta.current_page] || []
    tickets = ticketsPaginate.data
    state.ticketsPaginate = {...state.ticketsPaginate, ...{[ticketsPaginate.meta.current_page]: tickets}}
    state.ticketPaginateMeta = ticketsPaginate.meta
  },

  [types.ADD_CATEGORY_TICKETS](state, data) {
    state.categoryTickets = data
  },

  [types.ADD_TICKETS_STATUS](state, data) {
    state.ticketsStatus = data
  },

  [types.ADD_CUSTOMER_PRIORITIES](state, data) {
    state.customerPriorities = data
  },

  [types.ADD_TICKETS_CONTACTS](state, data) {
    state.contactsTickets = data
  },

  [types.ADD_CONTACT_PRODUCTS](state, {customer, data}) {
    let products = state.contactProducts[customer] || []
    products = data
    state.contactProducts = {...state.contactProducts, ...{[customer]: data}}
  },

  [types.ADD_CATEGORY_TICKET](state, data) {
    state.categoryTickets.push(data)
  },

  [types.ADD_CATEGORY_TICKET_STATUS](state, {id, data}) {
    let category = state.categoryTickets.find(x => x.id === id)
    if(category){
      category.status = data
    }
  },

  [types.CHANGE_CATEGORY_PRODUCT_STATUS](state, {category, product, status}) {
    let cat = state.categoryTickets.find(x => x.id === category)
    if(cat){
      cat.status[product] = status
    }
  },

  [types.ADD_TICKET](state, ticket) {
    let tkt = state.tickets.find(x => x.id === ticket.id)
    if(tkt){
      state.tickets = state.tickets.map(x => {
        if(x.id === ticket.id){
          return ticket
        }
        return x
      })
    }else {
      state.tickets.push(ticket)
    }

  },


}
