import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'


const initialState = {
  requests: [],
  meta_data: [],
  requestMerchants: [],
  accountStatus: [],
  merchantAccountStatus:[],
  currentRequest: {
    company: {},
    country: {},
    documents: []
  },
  currentMerchantRequest: {
    company: {},
    country: {},
    documents: [],
    account_types: [],
  }
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
