import * as types from './mutation-types'
import {BASE_DOKOUN_API_URL} from "@/Modules/Dokoun/services/api";

function getConfig(url, method, data) {
    return  {
        method: method,
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data
    };
}

export const advices = ({ commit, dispatch, state }, {page, search, limit, category}) => {
    let url = `${BASE_DOKOUN_API_URL}/advices?page=${page}&search=${search}&limit=${limit}&category_id=${category}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            commit(types.ADD_ADVICES, response.data)
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const advice = ({ commit, dispatch, state }, id) => {
    let url = `${BASE_DOKOUN_API_URL}/get/advices/${id}`
    const config = getConfig(url, 'get')
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeAdvice = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/store/advices`
    const config = getConfig(url, 'post', data)
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAdvice = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/update/advices/${data.id}`    
    const config = getConfig(url, 'put', data)
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAdvice = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/delete/advices/${data.id}`
    const config = getConfig(url, 'delete', data)
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeManyAdvices = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/advices/create-many-by-category`
    const config = getConfig(url, 'post', data)
    return new Promise((resolve, reject) => {
        window.axios.request(config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const importAdvices = ({ commit, dispatch, state }, data) => {
    let url = `${BASE_DOKOUN_API_URL}/import/advices`
    return new Promise((resolve, reject) => {
        window.axios.post(url, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}