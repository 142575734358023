import * as types from './mutation-types'

export default {

  [types.LOAD_CURRENCIES](state, currencies) {
    state.currencies = currencies.data
    state.metaData = currencies.meta
  },
  
  [types.ADD_CURRENCY](state, data) {
    state.currencies.push(data)
  },

  [types.LOAD_ALL_CURRENCIES](state, currencies) {
    state.allCurrencies = currencies.data
  },

  [types.LOAD_ALL_APP_CURRENCIES](state, currencies) {
    state.appCurrencies = currencies.data
  },
  
  [types.LOAD_DEFAULT_CURRENCY](state, currency) {
    state.default = currency
  }

}
