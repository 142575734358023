
export const getPaymentData = function (state) {
    return state.data
}

export const getPaymentStatus = function (state) {
    return state.status
}

export const getPaymentTypes = function (state) {
    return state.types
}


