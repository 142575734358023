const RequestLayout = () => import('@/Modules/Kobo/Request/layout/LayoutRequest')
const RequestSocietyList = () => import('@/Modules/Kobo/Request/views/Society/Index')
const RequestSocietyView = () => import('@/Modules/Kobo/Request/views/Society/View')
const RequestMerchant = () => import('@/Modules/Kobo/Request/views/Merchant/Merchant')
const MerchantDetails = () => import('@/Modules/Kobo/Request/views/Merchant/DetailsMerchant')

export default {
    path: "requests",
    name: 'kobo-requests',
    component: RequestLayout,
    redirect: { name: 'kobo-requests-society' },
    children: [
        {
            path: 'society',
            name: 'kobo-requests-society',
            component: RequestSocietyList,
        },
        {
            path: ':id/view',
            name: 'kobo-requests-society-view',
            component: RequestSocietyView,
        },
        {
            path: 'merchant',
            name: 'kobo-requests-merchant',
            component: RequestMerchant,
        },
        {
            path: ':id/merchant-details',
            name: 'kobo-merchant-details',
            component: MerchantDetails,
        }
    ]
}