import * as types from './mutation-types'

export const loadEstimates = ({ commit, dispatch, state }, {
        page, search, limit, status, estimate_number, from_date, to_date, contact_id, orderByField, orderBy
    }) => {
    let url = `/estimates?page=${page}&search=${search}&limit=${limit}&status=${status}&estimate_number=${estimate_number}
    &from_date=${from_date}&to_date=${to_date}&contact_id=${contact_id}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_ESTIMATES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createEstimate = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('estimates', data).then((response) => {
            commit(types.ADD_ESTIMATE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateEstimate = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('estimates/' + data.estimate_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showEstimate = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('estimates/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getStatus = ({ commit, dispatch, state }) =>{
    return new Promise((resolve, reject) => {
        window.axios.get('get/estimate/status').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadEstimateStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/estimate/status`).then((response) => {
            commit(types.LOAD_ESTIMATE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteEstimates = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('estimates/delete', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendEstimate = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('estimates/' + data.id + '/send', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeEstimateStatus = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('estimates/' + data.id + '/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
