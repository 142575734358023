import * as types from './mutation-types'

export const loadPayments = ({ commit, dispatch, state }, {
        page, search, limit, status, pay_type_id, from_date, to_date, orderByField, orderBy
    }) => {
    let url = `/payments?page=${page}&search=${search}&limit=${limit}&status=${status}&pay_type_id=${pay_type_id}
    &from_date=${from_date}&to_date=${to_date}&orderByField=${orderByField}&orderBy=${orderBy}`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.LOAD_PAYMENTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentStatus = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/payment/status`).then((response) => {
            commit(types.LOAD_PAYMENT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPaymentTypes = ({ commit, dispatch, state }, options) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/types`).then((response) => {
            commit(types.LOAD_PAYMENT_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendPayment = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/send/payment/${data.id}`, data).then((response) => {
            //commit(types.LOAD_PAYMENT_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const savePayment = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/payments', data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const cancelPayment = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/cancel/payment/${data.id}`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validatePayment = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/validate/payment/${id}`).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updatePayment = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`/payments/${data.id}`, data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deletePayment = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/payments/delete', data).then((response) => {
            //commit(types.ADD_CONTACT, response.data.contact)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPayment = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/payments/${id}`).then((response) => {
            //commit(types.LOAD_PAYMENT_PAID_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


