import * as types from './mutation-types'

export const loadApplications = ({ commit, dispatch, state }) => {
    let url = `/applications`
    return new Promise((resolve, reject) => {
        window.axios.get(url).then((response) => {
            commit(types.ADD_APPLICATIONS, response.data.applications)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}




