import * as types from './mutation-types'

export const loadCountries = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/countries').then((response) => {
            commit(types.LOAD_COUNTRIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

