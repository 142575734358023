import ConseilRoutes from '@/Modules/Dokoun/Conseils/route'
import CategoryRoutes from '@/Modules/Dokoun/Category/route'
import QuizRoutes from '@/Modules/Dokoun/Quiz/route'

export default {
    path: "dokoun",
    name: 'dokoun',
    children: [
        ConseilRoutes,
        CategoryRoutes,
        QuizRoutes
    ]
}