import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch} from 'vue'

export function usePermission() {
    const store = useStore()
    onMounted(() => {
        store.dispatch('permission/loadPermissions')
    })

    const permissions = computed(() => store.getters['permission/permissions'])

    function $can(permissionName){
        if(permissions && permissions.value.length){
            return permissions.value.indexOf(permissionName) !== -1
        }
        return false
    }

    return { $can }
}