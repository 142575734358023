import * as types from './mutation-types'

export const loadRoles = ({ commit, dispatch, state }, { limit='all', page=1, search='', orderBy, orderByField="name" }) => {

    return new Promise((resolve, reject) => {
        window.axios.get(`/roles?limit=${limit}&page=${page}&search=${search}`).then((response) => {
            commit(types.LOAD_ROLES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllRoles = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`roles`).then((response) => {
            commit(types.LOAD_ALL_ROLES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createRole = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('roles', data).then((response) => {
            commit(types.ADD_ROLE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRole = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        console.log(data)
        window.axios.put(`roles/${data.id}`, data).then((response) => {
            commit(types.UPDATE_ROLE, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const showRole = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('roles/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const attachPermissionsToRole = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('permissions/role', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const attachRoleToUser = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('user/roles', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteRole = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`roles/${id}`).then((response) => {
            commit(types.DELETE_ROLE, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
