import * as types from './mutation-types'

export const loadUnits = ({ commit, dispatch, state }, { limit='all', page=1, search='', orderBy, orderByField="name" }) => {

    return new Promise((resolve, reject) => {
        window.axios.get(`/units?limit=${limit}&page=${page}&search=${search}`).then((response) => {
            commit(types.LOAD_UNITS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllUnits = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/all/units`).then((response) => {
            commit(types.LOAD_ALL_UNITS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createUnit = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('units', data).then((response) => {
            commit(types.ADD_UNIT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateUnit = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('units/' + data.id, data).then((response) => {
            commit(types.UPDATE_UNIT, data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showUnit = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('units/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteUnit = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('units/' + id).then((response) => {
            commit(types.DELETE_UNIT, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
